import { Modal } from "react-bootstrap";

const FederalEmployeeCostModal = ({
  showModal,
  setShowModal,
  age60,
  age65,
  age70,
  age80,
}) => {
  return (
    <Modal
      size="sm"
      onHide={() => setShowModal(false)}
      show={showModal}
      dialogClassName="federalEmployeeCostModal"
    >
      <Modal.Header style={{ background: "#2d3436", color: "white" }}>
        <div className="col-md-11"> Cost @ RET</div>
        <div
          className="col-md-1 text-white c-pointer"
          onClick={() => setShowModal(false)}
        >
          {" "}
          X{" "}
        </div>
      </Modal.Header>
      <Modal.Body>
        <section>
          <div className="fed-main">
            <div
              className="fed-sub"
              style={{
                display: "flex",
                borderBottom: "1px solid lightgray",

                padding: "0 30px",
              }}
            >
              <h6>Age 60</h6>
              <h6 style={{ marginLeft: "40px" }}>${age60}/mo</h6>
            </div>
            <div
              className="fed-sub"
              style={{
                display: "flex",
                borderBottom: "1px solid lightgray",

                padding: "0 30px",
              }}
            >
              <h6>Age 65</h6>
              <h6 style={{ marginLeft: "40px" }}>${age65}/mo</h6>
            </div>
            <div
              className="fed-sub"
              style={{
                display: "flex",
                borderBottom: "1px solid lightgray",

                padding: "0 30px",
              }}
            >
              <h6>Age 70</h6>
              <h6 style={{ marginLeft: "40px" }}>${age70}/mo</h6>
            </div>
            <div
              className="fed-sub"
              style={{
                display: "flex",
                borderBottom: "1px solid lightgray",

                padding: "0 30px",
              }}
            >
              <h6>Age 80</h6>
              <h6 style={{ marginLeft: "40px" }}>${age80}/mo</h6>
            </div>
          </div>
        </section>
      </Modal.Body>
    </Modal>
  );
};

export default FederalEmployeeCostModal;
