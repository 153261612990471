import React from "react";

const Fegli = () => {
  return (
    <div className="article-section">
      <div className="container">
        <ol start="11" type="1">
          <li>
            Your Federal Benefits are broken down into Health Insurance, Life
            Insurance, and Retirement benefits that include FERS, TSP, and
            Social Security. Today we will review your life insurance and
            retirement benefits. We do not work with health insurance benefits;
            they are covered during open season.
          </li>
          <li>
            The first part of FEGLI we will talk about is called Basic Life.
            Career USPS Employees get this for free, however, ALL other
            government agencies such as the IRS or Social Security
            Administration have to pay 2/3 of the cost of this feature.
          </li>
          <li>
            <b>Basic Life</b> benefit is calculated by taking your annual base
            pay (not including overtime) which I see on your pay stub, is
            $50,276, and rounding it up to the nearest thousand and then adding
            $2000 to that. In your case, this brings your total Basic Life
            benefit to $53,000.
          </li>
          <li>
            <b>Extra Benefit</b> is also included with your basic life. All
            career employees get this benefit for free until the age of 45. You
            can see that at the age of 36, your multiplication factor is .9.
            This means your Extra Benefit is equal to .9 or 90% of your Basic
            Life Benefit.
          </li>
          <li>
            Everything reviewed so far falls under the Basic Life coverage, and
            as I said at the beginning is FREE to Postal Employees, while all
            other Government Agencies pay for this benefit. If you worked for
            any other government agency or department you would share in the
            cost. These savings equate to as much as $10–50 per month depending
            on your income. Any questions here?
          </li>
          <li>
            <b>FEGLI CODE</b> Next are the items you pay for over and above what
            you get for free. These items were elected probably back when you
            became a career employee. Option A, B and C. Let’s look at your
            Paystub and see exactly what you have.
          </li>
          <li>
            <b>Remember the IN2Z5</b> code on your pay stub (Note: Reference
            Paystub)? This code tells us what FEGLI options you have. IN tells
            me you’ve got more than just your Basic coverage Number 2 tells me
            your age and price bracket. We know that you’re between the ages of
            35 and 39, and your age determines what you pay. You also have the
            option code Z. This letter tells us exactly which options you chose.
          </li>
          <li>
            This BL tells us that you have Basic Life. A is for Option A. B-5 is
            for your Option B with a multiple of 5. C means you also elected
            Option C, which is your Family coverage, and the 5 tells me the
            amount of family coverage you elected.
          </li>
          <li>
            <b>OPTION A</b> Option A and is very simple. It provides you with a
            fixed amount of coverage. $10,000, and at your age, 36, you are
            currently paying $.__ for this benefit.
          </li>
          <li>
            <b>OPTION B</b> Next, we are going to look at Option B, which is
            typically the largest and most important part of the insurance
            benefits under FEGLI. Remember your code B-5, means that you have 5
            times your base pay. That is a good thing; you have opted for the
            maximum coverage available. The government offered 1x – 5x under
            this option. In your case we take your rounded base of $51,000 and
            multiply it by 5 for a total of $255,000. Can you see now why I said
            this is your most significant part of the FEGLI? It makes up most of
            your FEGLI life insurance.
          </li>
          <li>
            <b>OPTION C (Family)</b> Option C is family coverage. This Life
            Insurance coverage is for your spouse and children under the age of
            22. Remember the last digit in the FEGLI code (5)? This tells us
            that you opted for 5x times the Family Option. This provides $25,000
            of coverage on your spouse and $12,500 on each child until they
            reach the age of 22.
          </li>
          <li>
            <b>FEGLI Summary – Career</b> I told you earlier that we would talk
            about some of the gaps and pitfalls associated with your benefits,
            so let me show you some of the problems with the way the government
            has this coverage set up.
          </li>
          <li>
            First let’s go back to the Extra Benefit. Notice what happens to
            this coverage when you reach the age of 45. That’s right, it goes to
            zero. The benefit is taken away from you. You might be wondering
            why? Well, it’s strictly a result of age. Like many things in life,
            the cost goes up with age, so the Government has opted to
            discontinue it at that point rather than pay the higher cost.
          </li>
          <li>
            Now let’s look at Option B. Look at this chart with me. We see that
            at your current age of 36 you are paying $_____per thousand. Take
            that number and multiply it by the total of 255. So right now,
            you’re paying $_____ Per Pay for this coverage. As you can see, the
            problem with this coverage is that Option B is based on your age and
            continues to go up in price every five years. Now the rate you’re
            paying today isn’t necessarily a bad rate, the problem is that it
            continues to increase in price until Federal Employees are forced to
            cancel it.
          </li>
          <li>
            By the time you’re age 65, the FEGLI rate has gone up significantly
            ($137/pp, $298.35/mo. $3580/yr.). Obviously at this point this isn’t
            something you’ll be able to pay for, especially since the premium
            continues to rise. Look what happens by the time you get to Age 70.
            It goes to $.___ per thousand or $244.80/pp, $530.40/mo., or
            $6364/yr. We haven’t reviewed your retirement income yet, but do you
            think you’ll be able to pay $530 dollars a month for FEGLI Option B
            in retirement?
          </li>
          <li>
            Unfortunately, after paying up to 30-35,000 dollars in premiums
            often canceling the coverage before they even retire, the key to
            tackling FEGLI is making sure you don’t pay into it for longer than
            you need to, One of the things we do for Federal Employees is help
            them “lock in their rate”. Basically “stop the bleeding”, affording
            you the ability of holding on to that coverage for the length of
            time that makes sense for you and your family. Do you have any
            questions?
          </li>
          <li className="agent-tip">
            <strong>Agent Tip:</strong> At this point, stop talking and allow
            them to speak and share their opinion. It’s important to make sure
            at this point that they clearly see the problem.
          </li>
          <li>
            Let’s look at Option C, here again we have a surprise in store.
            Notice that Option C also increases in cost with age. Today it is
            $.27 per unit of coverage and goes up every 5 years. At your age of
            70 it will be $____ per unit, an increase of over 1140%.
          </li>
          <li>
            <b>FEGLI Summary – Retirement</b> or Age 65 Now let’s fast-forward
            to Retirement or Age 65. Some of your coverage starts to change. At
            this point your Basic Life and Option A will drop by 75%. This means
            your Basic coverage drops from $53,000 down to $13,250 and your
            Option A coverage down from $10,000 to $2,500.
          </li>
          <li className="agent-tip">
            <strong>Agent Tip:</strong> At this point, stop talking and allow
            them to speak and share their opinion. It’s important to make sure
            at this point that they clearly see the problem. Again, the problem
            is that 95% of the people we meet don’t realize this. The Government
            has done a poor job of communicating how this works.
          </li>
          <li>
            Keep in mind this is still a free benefit and the fact that it drops
            down to $13,250 isn’t so terrible, the real problem is that most of
            you don’t find out about it until it’s too late. The other issue is
            that at age 90, $13,250 will not feel like it does today. We must
            consider something called inflation. Inflation is approximately 3.5%
            annually. Meaning that a loaf of bread today might cost $4.00. At
            age 90 that same loaf of bread will cost AT LEAST $10.00. Like I
            said, knowing is half the battle.
          </li>
          <li>
            Now let’s look at the $255,000 of Option B coverage you have. We’ve
            already determined there will come a time when you can’t afford to
            keep this coverage in place. Like I said, by the time you reach age
            65 it will drop down by 100% over a couple of years. So basically,
            you pay for it, and pay on it, and then must cancel it because it
            got too expensive. The question is how long you pay into it before
            realizing this isn’t something you want.
          </li>
          <li className="agent-tip">
            <strong>Agent Tip:</strong> At this point, stop talking and allow
            them to speak and share their opinion. It’s important to make sure
            at this point that they clearly see the problem.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Fegli;
