import React from "react";

const Fers2 = () => {
  return (
    <div className="article-section">
      <div className="container">
        <ol start="38" type="1">
          <li>
            Now let’s put it together. We are assuming you will have __ years of
            service at retirement, and your monthly income before retirement
            should be up to about $4167. At retirement your income will drop to
            $$938, or a loss in monthly income immediately of $3229. Remember,
            this will also provide a monthly income to your spouse in the event
            you were to die first of $521.
          </li>
          <li className="agent-tip">
            <strong>Agent Tip:</strong> As you review these numbers, the key
            here is to highlight that they will be retiring on a significantly
            reduced paycheck.
          </li>
          <li>
            <strong>
              Question, do you think you could retire today on that amount of
              income?
            </strong>{" "}
            Of course, none of us could. Unfortunately, that is why we see so
            many retirees in our communities working at places like Wal-Mart.
            Not because they want to, but because they need to. Most of them
            didn’t learn about this stuff until they retired, which was
            obviously too late. Fortunately for you, we are talking about it
            when you still have a chance to do something about it.
          </li>
          <li>
            <strong>
              Fortunately, your FERS pension is just one of four incomes that
              you will have!
            </strong>{" "}
            In addition to your FERS pension, you will have:
            <ul style={{ listStyle: "disc" }}>
              <li>FERS Pension of $938</li>
              <li>Social Security</li>
              <li>TSP</li>
              <li>
                Other / ROTH IRA Which is what I will help you set up today.
                This will be your only tax-free source of income at retirement
                and will also help reduce your gross taxable income once
                retired.
              </li>
            </ul>
          </li>
          <li className="agent-tip">
            <strong>Agent Tip:</strong> This is the tell don’t sell portion of
            your presentation. You’re either confident with the solution or you
            are not. Federal Employees want to know what you suggest.
          </li>
          <li>
            <strong>Income Tax at Retirement</strong> One important thing to
            remember; almost everything you’re going to get from the Government
            is taxed. Essentially 97% of your FERS pension will be taxed. Most
            or all your Social Security checks will be taxed. ALL your TSP money
            will more than likely be taxed.
          </li>
          <li>
            Total tax liability will be between 20-30% depending on you and your
            spouse’s combined tax bracket and the state you live in.
          </li>
          <li>
            <strong>ROTH IRA</strong> The only real way to offset your taxable
            pendulum when you retire. The objective here is to help you save up
            as much as you can between now and retirement in a tax-free account.
          </li>
          <li>
            <strong>Reduced Income</strong> We have reviewed all four sources of
            income and detailed the estimated tax liability at retirement.
            Factor in your Health Insurance premiums at $250 dollars per month
            for individual and $400-500 for you and your spouse. You can see
            that although you have additional sources of income, it doesn’t make
            up for your living expenses at retirement.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Fers2;
