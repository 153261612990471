import React from "react";

const Welcome2 = () => {
  return (
    <div className="article-section" data-screen="welcome2">
      <div className="container">
        <ol>
          <li>
            <b>WARM UP</b> Hi (name), this is _____________ with MyFEDBenefits,
            how are you today? Hopefully you received my text message and were
            able to take a quick look at my BIO PAGE.
          </li>

          <li>
            <b>INTRODUCTION</b> As you can see, I’m a Federal Benefits
            Specialist. I work with anyone who falls within the Federal Benefits
            spectrum, the IRS, Social Security Administration, or Postal
            workers. Our job is to help people like you better understand your
            benefits.
          </li>

          <li>
            <b>Most of the Federal Employees</b> we work with end up serving the
            Government most of their lives never fully understanding how their
            benefits work and most importantly how to make the most of them. In
            some cases, they’re paying a lot of money for benefits they don’t
            even need. Basically, the Federal Benefits Program is a moving
            target, and at the end of the day, Federal Employees don’t really
            know what they can bank on when they finally get to the point of
            retirement. Does this sound familiar?
          </li>

          <li>
            <b>REASON FOR OUR MEETING</b> is to review your benefits, help you
            better understand them, make sure you maximize everything available
            to you and to make sure you’re not spending money on benefits you
            don’t need. We’ll also look at the pitfalls and gaps in your
            benefits and how it affects you at retirement. We also want to make
            sure you’re on track for retirement and more importantly the
            retirement you want and deserve.
          </li>

          <li>
            Also, now that I’m working with you, we will touch base each year to
            make sure you are on track. I will be sharing my screen with you as
            we continue. When I’m done, I will email you a copy of this
            document, it’s what is called the Benefits Planning Guide.
          </li>

          <li>
            <b>PAYSTUB REVIEW</b> If you can grab a recent pay stub, we can get
            started. It doesn’t have to be the last one, just a recent one.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Welcome2;
