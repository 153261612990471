import React, { useEffect, useState } from "react";
import userService from "../../services/UserService";
import {
  convertValue,
  formatCalculatedValue,
  showPdfStatus,
  formatInitialFormData,
  getPayload,
  updateArticleWindow,
  closeArticlePopup,
} from "../../services/helperService";
import { useLocation, useNavigate } from "react-router-dom";
import "./responsive.scss";
import { Button, Card, ProgressBar, Spinner } from "react-bootstrap";
import { useRef } from "react";
import Welcome from "./welcome/welcome";
import Welcome2 from "./welcome/welcome2";
import PaystubForm from "./paystub/paystubForm";
import PaystubForm2 from "./paystub/paystubForm2";
import FegliForm from "./fegli/fegliForm";
import FersForm from "./fers/fersForm";
import FersForm2 from "./fers/fersForm2";
import TspForm from "./tsp/tspForm";
import SocialSecurity from "./ss/socialSecurity";
import RainyDayFund from "./rdf/rainyDayFund";
import Summary from "./summary/summary";
import GapForm from "./gap/gapForm";
import BenefitForm from "./benefit/benefitForm";
import ProtectionForm from "./protection/protectionForm";
import RetirementForm from "./retirement/RetirementForm";
import RetirementForm2 from "./retirement/RetirementForm2";
import ClientSurveyForm from "./clientSurvey/ClientSurveyForm";
import EmailReport from "./emailReport/emailReport";
import AboutForm from "./moreAbout/AboutForm";
import AboutForm2 from "./moreAbout/AboutForm2";
import FerAnnuityForm from "./fersAnnuity/fersAnnuity";
import ThriftSavingForm from "./thriftSaving/ThriftSaving";
import PensionForm from "./pensionsAndIncome/pensionForm";
import ExpenseForm from "./expense/ExpenseForm";
import EmergencyForm from "./emergencySavings/EmergencyForm";
import TspOptionForm from "./tspOption/tspOptionForm";
import ConclusionForm from "./conclusion/conclusionForm";
import Congrets from "./congrets/congrets";
import OverView from "./OverView/OverView";

import Container from "../container/index";
import "./benefitPlanner.scss";
import moment from "moment";
import benefitService from "../../services/BenefitService";
import {
  intitialTabs,
  basicTabs,
  initialAvailableScreens,
  commonScreenSection,
  ageRelatedScreens,
} from "../../constants";
import SaveAlert from "../modal/saveAlert";
import { usePlannerContext } from "../../contexts/planner.contexts";
import jwt_decode from "jwt-decode";

const BenefitPlanner = ({ offlineSyncProBar }) => {
  const {
    plannerData,
    setPlannerData,
    onChangeStatus,
    setOnChangeStatus,
    Tab,
    setTab,
    loader,
    setLoader,
    userDetails,
    setUserdetails,
  } = usePlannerContext();
  let navigate = useNavigate();
  const location = useLocation();

  const [sideBar, setSideBar] = useState(initialAvailableScreens);
  const [allScreens, setAllScreens] = useState([]);
  const [validate, setValidate] = useState({});
  const [tabList, setTabList] = useState(intitialTabs);
  const [showModal, setShowModal] = useState({ status: false, nextRoute: "" });

  const [isHookExecuted, setHookExecuted] = useState(false);
  const [offlineSyncContainer, setOfflineSyncContainer] = useState(false);

  const handleStatus = (type = "save") => {
    let route = showModal.nextRoute;
    setOnChangeStatus(false);
    if (type == "save") {
      const form = document.getElementsByTagName("form");
      if (form && form[0]) {
        form[0].requestSubmit();
      }
      if (showModal.nextRoute) {
        setTimeout(() => {
          navigate(route);
        }, 1000);
        setShowModal({ status: false });
      }
    }
    if (type == "discard") {
      setShowModal({ status: false });
      navigate(route);
    }
  };

  const [showDiv, setShowDiv] = useState(false);
  const timeoutRef = useRef(null);
  const sideBarReference = useRef(null);

  useEffect(() => {
    if (!showDiv) {
      setTimeout(() => {
        if (sideBarReference.current) {
          sideBarReference.current.scrollTop = 0;
        }
      }, 500);
    }
  }, [showDiv]);

  useEffect(() => {
    const currentTab = getCurrentTabName();
    let payload = plannerData;
    if (payload["dob"]) {
      let pdfStatus = showPdfStatus(plannerData["dob"], currentTab);
      payload = { ...payload, pdfStatus };
      setPlannerData(payload);
    }
    setTab(currentTab);
  }, [location]);

  useEffect(() => {
    if (plannerData["dob"] && !isHookExecuted) {
      getScreens(plannerData["dob"]);
      setHookExecuted(true);
    }
  }, [plannerData["dob"], isHookExecuted]);

  useEffect(() => {
    setLoader(true);
    let user = userService.getLoggedInUser();
    if (user) {
      setUserdetails(user);
    }

    getPlanner();
    clearTimeout(timeoutRef.current);
  }, []);

  const handleMouseEnter = () => {
    timeoutRef.current = setTimeout(() => {
      setShowDiv(true);
    }, 500);
  };

  const handleMouseLeave = () => {
    clearTimeout(timeoutRef.current);

    setShowDiv(false);
  };

  const handlefersCalculations = (
    value,
    name,
    data,
    precentage,
    isJustcalculations = false
  ) => {
    if (!isJustcalculations) {
      setOnChangeStatus(true);
    }
    let payload = {};
    if (name == "yearsOfService" || name == "high3Avg") {
      let data = convertValue(value);
      let multiplier =
        name == "yearsOfService"
          ? convertValue(data["high3Avg"])
          : convertValue(data["yearsOfService"]);
      const finalValue = (data * multiplier * precentage) / 100 / 12;
      const fersBasicAnnuity25 = finalValue * 0.25;
      const fersBasicAnnuity95 = finalValue * 0.95;
      const fersBasicAnnuity50 = finalValue * 0.5;
      const fersBasicAnnuity90 = finalValue * 0.9;

      if (finalValue) {
        payload = {
          [name]: value,
          fersBasicAnnuity: formatCalculatedValue(finalValue),
          fersBasicAnnuity25: formatCalculatedValue(fersBasicAnnuity25),
          fersBasicAnnuity95: formatCalculatedValue(fersBasicAnnuity95),
          fersBasicAnnuity50: formatCalculatedValue(fersBasicAnnuity50),
          fersBasicAnnuity90: formatCalculatedValue(fersBasicAnnuity90),
        };
      }
      if (isJustcalculations) {
        return {};
      } else {
        setPlannerData({
          ...plannerData,
          ...payload,
        });
      }
    }
  };

  // calculations
  const calculateMRAandShowIt = (planData, isUpdateMRA = false) => {
    const dobDate = moment(planData["dob"]);
    const age = moment().diff(dobDate, "years", true);

    let militaryYears = 0;

    if (
      planData &&
      planData["have_military_service"] == "yes" &&
      planData["s2s3_receiving_pension"] == "no"
    ) {
      let serviceYears = moment.duration({
        years: parseInt(planData["military_service_years"]),
        months: parseInt(planData["military_service_months"]),
      });
      militaryYears = serviceYears.asYears();
    }

    let service = "";
    if (militaryYears) {
      let startDate = moment(planData["lite_pay_start_year"])
        .subtract(militaryYears, "years")
        .format("YYYY-MM-DD");

      service = moment(startDate).diff(dobDate, "years", true);
    } else {
      service = moment(planData["lite_pay_start_year"]).diff(
        dobDate,
        "years",
        true
      );
    }

    const bornYear = dobDate.year();

    let { MRA, yos } = getMRA(bornYear, service, planData);

    MRA = MRA / 12;
    yos = yos / 12;

    let retirementDate = "";
    if (MRA) {
      let remainYears = MRA - age;
      retirementDate = moment(dobDate)
        .add(remainYears, "years")
        .format("YYYY-MM-DD");
      if (remainYears > 0) {
        retirementDate = moment(dobDate).add(MRA, "years").format("YYYY-MM-DD");

        if (MRA % parseInt(MRA) == 0) {
          let retDate = moment(retirementDate);
          let db = moment(dobDate);
          retirementDate = retDate.format("YYYY") + "-" + db.format("MM-DD");
        } else {
          let retDate = moment(retirementDate);
          let db = moment(planData["lite_pay_start_year"]);
          retirementDate = retDate.format("YYYY") + "-" + db.format("MM-DD");
        }
      } else {
        let dateDifference = moment().diff(retirementDate, "years");
        if (dateDifference > 0) {
          retirementDate = moment(dobDate)
            .add(age, "years")
            .format("YYYY-MM-DD");
          retirementDate = moment().format("YYYY-MM-DD");
          MRA = age;
        }
      }
    }

    let startAge = moment(retirementDate).set({
      hour: 0,
      minute: 0,
      second: 0,
    });

    if (planData["retirementDate"]) {
      let diff = moment(planData["retirementDate"]).diff(startAge, "days");

      if (diff > 0) {
        // setError({ fers: true });
      }
    }

    let years = startAge.diff(dobDate, "year");
    dobDate.add(years, "years");

    let months = startAge.diff(dobDate, "months");

    dobDate.add(months, "months");
    let s2s5_age_to_start_taking_income = "";
    if (months == "0") {
      s2s5_age_to_start_taking_income = years ? years : 0;
    } else {
      s2s5_age_to_start_taking_income =
        (years ? years : 0) + " & " + (months ? months : 0) + " mos. ";
    }

    let payload = {
      yearsOfService: planData["yearsOfService"]
        ? convertValue(planData["yearsOfService"])
        : 0,
      s2s5_age_to_start_taking_income: isUpdateMRA
        ? s2s5_age_to_start_taking_income
        : planData["s2s5_age_to_start_taking_income"]
        ? planData["s2s5_age_to_start_taking_income"]
        : "",
      desired_retirement_date: planData["desired_retirement_date"]
        ? moment(planData["desired_retirement_date"])
        : null,
    };

    if (planData["retirement_date_unknown"] == "1") {
      let s2s5_age_to_start_taking_income = "";
      if (months == "0") {
        s2s5_age_to_start_taking_income = years ? years : 0;
      } else {
        s2s5_age_to_start_taking_income =
          (years ? years : 0) + " & " + (months ? months : 0) + " mos. ";
      }
      let MRA = parseFloat(years);
      MRA += months ? parseFloat(months) / 12 : 0;
      payload = {
        s2s5_age_to_start_taking_income,
        MRA,
        desired_retirement_date: retirementDate ? moment(retirementDate) : null,
        yearsOfService: yos ? formatCalculatedValue(yos) : 0,
        years: years,
      };
    }

    let diffMonth = moment(payload["desired_retirement_date"]).diff(
      moment(),
      "months"
    );

    if (diffMonth < 0) {
      diffMonth = 0;
    }
    let servicePayload = getServicePayload(service, payload);

    let srsyears = payload["yearsOfService"]
      ? convertValue(payload["yearsOfService"])
      : 0;

    if (
      planData.have_military_service == "yes" &&
      planData.s2s3_receiving_pension == "no"
    ) {
      let month = convertValue(plannerData.military_service_years) * 12;
      month = month + convertValue(plannerData.military_service_months);
      let year = month / 12;
      srsyears = srsyears - year;
    }
    let age_62_ss_benefit = planData["age_62_ss_benefit"]
      ? convertValue(planData["age_62_ss_benefit"])
      : 1300;
    const Srs = convertValue(age_62_ss_benefit) * (srsyears / 40);

    return {
      yearsOfService: yos ? yos : formatCalculatedValue(yos),
      yos: convertValue(yos),
      retirementDate,
      diffMonth: diffMonth ? diffMonth : 0,
      MRA,
      baseMRA: MRA,
      Srs: formatCalculatedValue(Srs),
      age_62_ss_benefit: formatCalculatedValue(age_62_ss_benefit),
      yearsOfServiceat62: formatCalculatedValue(srsyears),
      retirementYears: years,
      retirementMonths: months,
      ...servicePayload,
      ...payload,
    };
  };

  const getMRA = (bornYear, CareereYear, planData) => {
    let startDate = CareereYear ? CareereYear : 1;

    startDate = startDate * 12;

    startDate = Math.round(startDate);
    let countedAge = startDate;
    let yos = 0;

    let mraAge = 0;

    if (bornYear < 1948) {
      mraAge = 662;
    }
    if (bornYear == 1949) {
      mraAge = 664;
    }
    if (bornYear == 1950) {
      mraAge = 666;
    }
    if (bornYear == 1951) {
      mraAge = 668;
    }
    if (bornYear >= 1953 && bornYear <= 1964) {
      mraAge = 672;
    }
    if (bornYear == 1965) {
      mraAge = 674;
    }
    if (bornYear == 1966) {
      mraAge = 676;
    }
    if (bornYear == 1967) {
      mraAge = 678;
    }
    if (bornYear == 1968) {
      mraAge = 680;
    }
    if (bornYear == 1969) {
      mraAge = 682;
    }
    if (bornYear >= 1970) {
      mraAge = 684;
    }

    if (countedAge) {
      while (true) {
        if (planData["special_provisions"] == "1") {
          if ((countedAge >= 600 && yos >= 240) || yos >= 300) break;
        } else {
          if (
            (countedAge >= 744 && yos >= 60) ||
            (countedAge >= 720 && yos >= 240) ||
            (countedAge >= mraAge && yos >= 360)
          )
            break;
        }

        countedAge++;
        yos++;
      }
    }

    if (
      planData["desired_retirement_date"] &&
      planData["retirement_date_unknown"] == 0
    ) {
      let dobdate = moment(planData["dob"]);
      let selectedDate = moment(planData["desired_retirement_date"]);

      let years = selectedDate.diff(dobdate, "year");
      dobdate.add(years, "years");

      let months = selectedDate.diff(dobdate, "months");
      countedAge = parseFloat(years * 12);
      countedAge += months ? parseFloat(months) : 0;

      let joinDate = moment(planData["lite_pay_start_year"]);

      let yosyears = selectedDate.diff(joinDate, "year");

      let yosmonths = selectedDate.diff(joinDate, "months");
      yos = parseFloat(yosyears * 12);
      yos += months ? parseFloat(yosmonths) : 0;
    }

    return { MRA: countedAge, yos };
  };

  const getServicePayload = (service, data = plannerData) => {
    let payload = {};

    if (data["special_provisions"] == "1") {
      let yearsOfService1 = "";
      let yearsOfService2 = "";
      if (service > 20) {
        yearsOfService1 = 20;
        yearsOfService2 = service - 20;
      } else {
        yearsOfService1 = service;
        yearsOfService2 = 0;
      }

      let finalValue1 = "";
      let finalValue2 = "";
      if (yearsOfService1) {
        let multiplier = convertValue(data["high3Avg"]);

        finalValue1 = (yearsOfService1 * multiplier * 1.7) / 100 / 12;
      }

      if (yearsOfService2) {
        let multiplier = convertValue(data["high3Avg"]);

        finalValue2 = (yearsOfService2 * multiplier * 1) / 100 / 12;
      }

      let fersBasicAnnuity = finalValue1 + finalValue2;
      const fersBasicAnnuity25 = fersBasicAnnuity * 0.25;
      const fersBasicAnnuity95 = fersBasicAnnuity * 0.95;
      const fersBasicAnnuity50 = fersBasicAnnuity * 0.5;
      const fersBasicAnnuity90 = fersBasicAnnuity * 0.9;
      payload = {
        yearsOfService1: formatCalculatedValue(yearsOfService1),
        yearsOfService2: formatCalculatedValue(yearsOfService2),
        fersBasicAnnuity1: formatCalculatedValue(finalValue1),
        fersBasicAnnuity2: formatCalculatedValue(finalValue2),
        fersBasicAnnuity: formatCalculatedValue(fersBasicAnnuity),
        fersBasicAnnuity25: formatCalculatedValue(fersBasicAnnuity25),
        fersBasicAnnuity95: formatCalculatedValue(fersBasicAnnuity95),
        fersBasicAnnuity50: formatCalculatedValue(fersBasicAnnuity50),
        fersBasicAnnuity90: formatCalculatedValue(fersBasicAnnuity90),
      };
    } else if (service >= 20 && data["MRA"] >= 62) {
      payload = handlefersCalculations(
        service,
        "yearsOfService",
        data,
        1.1,
        true
      );
    } else {
      payload = handlefersCalculations(
        service,
        "yearsOfService",
        data,
        1,
        true
      );
    }

    return payload;
  };

  const getCurrentTabName = () => {
    let path = location && location.pathname && location.pathname.split("/");
    let tabName = path && path[path.length - 1];
    if (tabName && tabList && tabList.includes(tabName)) {
      return tabName;
    } else {
      let url = window.location.pathname;
      const idPattern = /\/(\d+)(\/|$)/;
      const match = url.match(idPattern);

      let bpgId =
        match && match[1]
          ? match[1]
          : plannerData && plannerData["id"]
          ? plannerData["id"]
          : "";

      if (bpgId) {
        navigate(`/bpg/${bpgId}/welcome`);
      }
      return "welcome";
    }
  };

  // const getUserDetails = async (id, token) => {
  //   if (id) {
  //     const {
  //       data: { data },
  //     } = await userService.getUser(id, token);

  //     if (data) {
  //       localStorage.setItem("user", JSON.stringify(data));
  //     }
  //   }
  // };

  const getPlanner = (updatePlanner = true) => {
    let token = localStorage.getItem("token");
    let url = window.location.pathname;
    const idPattern = /\/(\d+)(\/|$)/;
    const match = url.match(idPattern);
    let bpgId = match && match[1];
    setTimeout(async () => {
      let plan = localStorage.getItem("benefitPlan");
      if (plan) {
        plan = JSON.parse(plan);
      } else {
        plan = {};
      }

      let sidebar = plan["available_screens"]
        ? plan["available_screens"]
        : initialAvailableScreens;

      const localToken = localStorage.getItem("token");

      const decodedToken = jwt_decode(localToken);

      if (bpgId) {
        const data = await benefitService.getPlanner(bpgId, token);

        // await getUserDetails(plan && plan["profileid"], token);

        if (data && data["data"] && data["data"]["data"]) {
          setLoader(false);
          plan = data && data["data"] && data["data"]["data"];
          sidebar = plan["available_screens"]
            ? plan["available_screens"]
            : initialAvailableScreens;
          plan = { ...plan, bpg_access: decodedToken.data.bpg_access };
          localStorage.setItem("benefitPlan", JSON.stringify(plan));
          window.dispatchEvent(new Event("storage"));
        } else {
          setLoader(false);
          plan = { id: bpgId, available_screens: sidebar };
          localStorage.setItem("benefitPlan", JSON.stringify(plan));
          window.dispatchEvent(new Event("storage"));
        }
      }

      let tabs =
        sidebar && sidebar.length > 0 && sidebar.map((data) => data["code"]);
      if (tabs && tabs.length > 0) {
        tabs = [...basicTabs, ...tabs, "benefits-overview"];
        if (decodedToken.data.bpg_access == 2) {
          tabs = tabs.filter((object) => {
            return !ageRelatedScreens.find(
              (otherObject) => otherObject.code === object.code
            );
          });
        }
        setTabList(tabs);
      }
      sidebar = sidebar.filter(
        ({ code }) =>
          code && code !== "congrats" && code !== "benefits-overview"
      );

      if (decodedToken.data.bpg_access == 2) {
        sidebar = sidebar.filter((object) => {
          return !ageRelatedScreens.find(
            (otherObject) => otherObject.code === object.code
          );
        });
      }

      setAllScreens(sidebar);
      setSideBar(sidebar);
      if (updatePlanner) {
        let payload = calculateMRAandShowIt(plan);
        if (plan["dob"]) {
          const currentTab = getCurrentTabName();

          const pdfStatus = showPdfStatus(plan["dob"], currentTab);
          plan["pdfStatus"] = pdfStatus ? pdfStatus : "";
        }

        plan = formatInitialFormData(plan, payload);

        setPlannerData(plan);
      }

      // setPlannerData({
      //   ...plan,
      //   bpg_access: decodedToken.data.bpg_access,
      // });

      let user = userService.getLoggedInUser();
      if (user) {
        setUserdetails(user);
      }
      setLoader(false);
      localStorage.removeItem("url");
    }, [1000]);
  };

  const handleNavigation = (route) => {
    if (Tab != route) {
      if (onChangeStatus) {
        setShowModal({ status: true, nextRoute: route });
      } else {
        navigate(route);
      }
    }
  };

  const handlePdfReport = () => {
    let user = userService.getAccess();
    if (true) {
      let { id, contact_id } = plannerData;
      if (id && contact_id) {
        let customeId = contact_id + "-" + id;
        customeId = window.btoa(customeId.toString());
        window.open(
          window.location.origin + "/view-summary-report/" + customeId,
          "_blank"
        );
      }
    } else {
      let { id, profileid } = plannerData;
      if (id && profileid) {
        let customeId = profileid + "-" + id;
        customeId = window.btoa(customeId.toString());
        window.open(
          window.location.origin + "/view-summary-report/" + customeId,
          "_blank"
        );
      }
    }
  };

  const getScreens = (dob) => {
    const finalScreens = [];
    let age = moment().diff(dob, "years");

    if (age >= 55) {
      const allowedSites = allScreens;
      allowedSites &&
        allowedSites.length > 0 &&
        allowedSites.map((screen) => {
          const status =
            sideBar &&
            sideBar.length > 0 &&
            sideBar.find(({ code }) => code == screen["code"]);
          if (status) {
            finalScreens.push(status);
          } else {
            finalScreens.push(screen);
          }
        });
    } else {
      const allowedSites = commonScreenSection;
      allowedSites &&
        allowedSites.length > 0 &&
        allowedSites.map((screen) => {
          const status =
            sideBar &&
            sideBar.length > 0 &&
            sideBar.find(({ code }) => code == screen["code"]);
          if (status) {
            finalScreens.push(status);
          } else {
            finalScreens.push(screen);
          }
        });
    }

    let screen = initialAvailableScreens;
    if (finalScreens && finalScreens.length > 0) {
      screen = finalScreens;
    }

    let tabs =
      screen && screen.length > 0 && screen.map((data) => data["code"]);
    if (tabs && tabs.length > 0) {
      tabs = [...basicTabs, ...tabs, "congrats", "benefits-overview"];
    }

    setSideBar(screen);
    setTabList(tabs);
  };

  const submit = (event, submitedValues = plannerData) => {
    const submitter = event["nativeEvent"] && event["nativeEvent"]["submitter"];

    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    setValidate({});

    let validity =
      userService.getAccess().bpg_skip_req == 1 ? true : form.checkValidity();

    if (validity === false) {
      if (submitter || Tab == "email-report") {
        if (form["name"] == "paystubForm") {
          setValidate({ paystubForm: true });
        }

        if (form["name"] == "paystubForm2") {
          setValidate({ paystubForm2: true });
        }
        if (form["name"] == "fegliForm") {
          setValidate({ fegliForm: true });
        }

        if (form["name"] == "FersForm") {
          setValidate({ FersForm: true });
        }

        if (form["name"] == "tspForm") {
          setValidate({ tspForm: true });
        }

        if (form["name"] == "SocialSecurity") {
          setValidate({ SocialSecurity: true });
        }

        if (form["name"] == "esForm") {
          setValidate({ esForm: true });
        }

        if (form["name"] == "emailReportForm") {
          setValidate({ emailReportForm: true });
        }
        if (form["name"] == "conclusionForm") {
          setValidate({ conclusionForm: true });
        }
        if (form["name"] == "aboutForm") {
          setValidate({ aboutForm: true });
        }
        if (form["name"] == "fersAnnuity") {
          setValidate({ fersAnnuity: true });
        }
        if (form["name"] == "thriftSavingForm") {
          setValidate({ thriftSavingForm: true });
        }
        if (form["name"] == "pensionForm") {
          setValidate({ pensionForm: true });
        }
      }
    } else {
      const currentTab = Tab;
      let itemList = sideBar;
      itemList =
        itemList &&
        itemList.length > 0 &&
        itemList.map((data) => {
          if (data["code"] == Tab) {
            data["completed"] = 1;
            return data;
          } else {
            return data;
          }
        });

      setSideBar(itemList);
      updateForm();
      let index = tabList.indexOf(currentTab);
      index++;
      let ferscalculationPayload = {};
      if (currentTab == "pay") {
        getScreens(submitedValues["dob"]);
        if (
          submitedValues["dob"] !== plannerData["dob"] ||
          submitedValues["lite_pay_start_year"] !==
            plannerData["lite_pay_start_year"] ||
          submitedValues["special_provisions"] !==
            plannerData["special_provisions"]
        ) {
          ferscalculationPayload = calculateMRAandShowIt(submitedValues);
        }
      }
      setPlannerData({
        ...plannerData,
        ...submitedValues,
        ...ferscalculationPayload,
      });
      setOnChangeStatus(false);
      if (submitter || Tab == "email-report") {
        navigate(tabList[index]);
      }

      if (index > 2) {
        let payload = getPayload(currentTab, submitedValues);
        benefitService
          .updatePlanner({
            ...payload,
            screen: Tab,
          })
          .then((plandata) => {});
      }
    }
  };

  const updateForm = () => {
    let items = JSON.stringify(plannerData);
    localStorage.setItem("benefitPlan", items);
    window.dispatchEvent(new Event("storage"));
  };

  //Offline syncronization modal handler
  const handleOnlineEvent = () => {
    if (JSON.parse(localStorage.getItem("queuedRequests"))) {
      setOfflineSyncContainer(true);
    }
  };

  useEffect(() => {
    // Add event listener for the 'online' event
    window.addEventListener("online", handleOnlineEvent);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("online", handleOnlineEvent);
    };
  }, []);

  useEffect(() => {
    updateArticleWindow(location.pathname.split("/").slice(-1)[0]);
  }, [location]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      closeArticlePopup();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleOfflineSyncContainer = () => {
    if (offlineSyncProBar >= 100) {
      setOfflineSyncContainer(false);
    }
  };

  return (
    <Container>
      <>
        {Tab === "benefits-overview" && (
          <div>
            <button
              className="benefits-overView-refresh-button refresh-icon p-1"
              onClick={() => {
                setLoader(true);
                getPlanner();
              }}
            >
              <div className="">
                {loader ? (
                  <Spinner
                    className="text-center icon-lg d-flex justify-content-center align-items-center m-0 p-0"
                    role="status"
                    variant="light"
                    size="sm"
                  />
                ) : (
                  <i class="fa fa-refresh icon-lg d-flex justify-content-center align-items-center"></i>
                )}
              </div>
            </button>
          </div>
        )}
        {plannerData["pdfStatus"] && (
          <div onClick={handlePdfReport} className="pdf-icon c-pointer">
            <i class="fa fa-file-pdf-o"></i>
          </div>
        )}
        <div className="benefit-card">
          {offlineSyncContainer && (
            <div class="offlineSyncIconContainer text-center text-nowrap">
              <p>Please wait while data is being synchronizing</p>
              <ProgressBar
                now={offlineSyncProBar}
                label={`${offlineSyncProBar}%`}
                className="progressBar"
              />
              {offlineSyncProBar >= 100 && (
                <Button
                  className="mt-2 progress-continue"
                  onClick={handleOfflineSyncContainer}
                >
                  Click to continue
                </Button>
              )}
            </div>
          )}
          <Card className="benefit-section">
            <div className="side-penal">
              <div
                className="sideSection"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <div>
                  <div
                    ref={sideBarReference}
                    className={`collapse-nav-td ${showDiv && "open"}`}
                  >
                    <div className="screencompleted-area">
                      <div
                        className="actionPanelHead"
                        style={{ width: "auto", whiteSpace: "nowrap" }}
                      >
                        {showDiv ? (
                          <i className="fa fa-minus fa-icon"></i>
                        ) : (
                          <i className="fa fa-plus fa-icon"></i>
                        )}{" "}
                        Section Completion
                      </div>

                      <div className="actionPanelBody">
                        <ul
                          className="screenLinks"
                          style={{ listStyle: "none", marginLeft: 0 }}
                        >
                          {sideBar &&
                            sideBar.length > 0 &&
                            sideBar.map((val, i) => (
                              <li
                                className={`screen-list-item main-screen ${
                                  Tab === val["code"] && "active opned"
                                } ${val["main"] && "sub-screen"} ${
                                  val["main"] == "more-about-you" &&
                                  "sub-compact-screen"
                                }`}
                              >
                                <a
                                  className="completed screen-link"
                                  onClick={() => handleNavigation(val["code"])}
                                >
                                  {val["completed"] ? (
                                    <i className="fa fa-check-square-o"></i>
                                  ) : (
                                    <i className="fa fa-square-o"></i>
                                  )}

                                  <span className="screen-title">
                                    {i + 1}. {val["title"]}
                                  </span>
                                </a>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {loader ? (
                <div className="loading-screen2">
                  <div className="loader-center">
                    <Spinner animation="border" variant="primary" />
                  </div>
                </div>
              ) : (
                <div className="w-100">
                  {Tab == "welcome" && <Welcome submit={submit} />}

                  {Tab == "welcome2" && <Welcome2 submit={submit} />}

                  {Tab == "pay" && (
                    <PaystubForm
                      submit={submit}
                      validated={
                        validate && validate["paystubForm"] ? true : false
                      }
                    />
                  )}
                  {Tab == "pay-s2" && (
                    <PaystubForm2
                      validated={
                        validate && validate["paystubForm2"] ? true : false
                      }
                      submit={submit}
                    />
                  )}
                  {Tab == "fegli" && (
                    <FegliForm
                      validated={
                        validate && validate["fegliForm"] ? true : false
                      }
                      submit={submit}
                    />
                  )}

                  {Tab == "fers" && (
                    <FersForm
                      validated={
                        validate && validate["FersForm"] ? true : false
                      }
                      submit={submit}
                    />
                  )}
                  {Tab == "fers-s2" && <FersForm2 submit={submit} />}
                  {Tab == "tsp" && (
                    <TspForm
                      validated={validate && validate["tspForm"] ? true : false}
                      submit={submit}
                    />
                  )}

                  {Tab == "ss" && (
                    <SocialSecurity
                      validated={
                        validate && validate["SocialSecurity"] ? true : false
                      }
                      submit={submit}
                    />
                  )}
                  {Tab == "es" && (
                    <RainyDayFund
                      validate={validate && validate["esForm"] ? true : false}
                      submit={submit}
                    />
                  )}
                  {Tab == "gap" && <GapForm submit={submit} />}
                  {Tab == "summary" && <Summary submit={submit} />}
                  {Tab == "benefits" && (
                    <BenefitForm submit={submit} formData={plannerData} />
                  )}
                  {Tab == "protection" && (
                    <ProtectionForm
                      validate={validate && validate["sale"] ? true : false}
                      submit={submit}
                      formData={plannerData}
                    />
                  )}
                  {Tab == "retirement" && (
                    <RetirementForm submit={submit} formData={plannerData} />
                  )}
                  {Tab == "retirement-s2" && (
                    <RetirementForm2 submit={submit} formData={plannerData} />
                  )}
                  {Tab == "client-survey" && (
                    <ClientSurveyForm submit={submit} />
                  )}

                  {Tab == "more-about-you" && <AboutForm submit={submit} />}
                  {Tab == "more-about-you-s2" && (
                    <AboutForm2
                      validated={
                        validate && validate["aboutForm"] ? true : false
                      }
                      submit={submit}
                    />
                  )}
                  {Tab == "fers-annuity" && (
                    <FerAnnuityForm
                      validate={
                        validate && validate["fersAnnuity"] ? true : false
                      }
                      submit={submit}
                    />
                  )}
                  {Tab == "tsp-ret" && (
                    <ThriftSavingForm
                      submit={submit}
                      validated={
                        validate && validate["thriftSavingForm"] ? true : false
                      }
                    />
                  )}
                  {Tab == "pensions-and-income" && (
                    <PensionForm
                      submit={submit}
                      formData={plannerData}
                      validated={
                        validate && validate["pensionForm"] ? true : false
                      }
                    />
                  )}
                  {Tab == "expenses" && (
                    <ExpenseForm submit={submit} formData={plannerData} />
                  )}
                  {Tab == "es-ret" && (
                    <EmergencyForm
                      submit={submit}
                      validated={validate && validate["esForm"] ? true : false}
                    />
                  )}
                  {Tab == "tsp-options" && <TspOptionForm submit={submit} />}
                  {Tab == "conclusion" && (
                    <ConclusionForm
                      submit={submit}
                      validated={
                        validate && validate["conclusionForm"] ? true : false
                      }
                    />
                  )}
                  {Tab == "email-report" && (
                    <EmailReport
                      submit={submit}
                      validated={
                        validate && validate["emailReportForm"] ? true : false
                      }
                    />
                  )}

                  {Tab == "congrats" && <Congrets />}

                  {Tab == "benefits-overview" && (
                    <>
                      <OverView />
                    </>
                  )}
                </div>
              )}
            </div>
          </Card>
        </div>

        <SaveAlert
          showModal={showModal["status"]}
          setShowModal={setShowModal}
          handleStatus={handleStatus}
        />
      </>
    </Container>
  );
};

export default BenefitPlanner;
