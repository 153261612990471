import React from "react";

const Fers = () => {
  return (
    <div className="article-section">
      <div className="container">
        <ol start="34" type="1">
          <li>
            <strong>FERS</strong> Now let’s look at your retirement program.
            Remember the RETIRE 8 section on your pay stub? (Reference back to
            the pay stub?) This tells me that you’re under the FERS Retirement
            System.
          </li>

          <li>
            <strong>
              Your FERS income at retirement is based on two factors.
            </strong>
            <ul style={{ listStyle: "disc" }}>
              <li>
                High Three Average Salary – these are your highest three
                consecutive years of making the most amount of money. Not
                including overtime. For most people it will be their last three
                years before retirement.
              </li>
              <li>
                Years of Creditable Service - these are the total number of
                years that you have with the government, as a career employee.
              </li>
            </ul>
          </li>

          <li>
            <strong>FERS Estimate Notice</strong> that of the three numbers I
            chose the smaller one (No Survivor, 25% Survivor and 50% Survivor in
            the BPG).
          </li>

          <li>
            <strong>
              The difference in the three numbers is what you will forfeit for
              survivor benefit.
            </strong>{" "}
            This is a check to your spouse if you die before they do during
            retirement. For most families this is not something they can live
            without. When you get close to retirement, we’ll go over these
            options with you in more detail.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Fers;
