import React from "react";

const Summary = () => {
  return (
    <div className="article-section" data-screen="summary">
      <div className="container">
        <p>
          This screen does not contain follow-along content. Please improvise
          based on training.
        </p>
      </div>
    </div>
  );
};

export default Summary;
