import React from "react";

const Summary = () => {
  return (
    <div className="article-section">
      <div className="container">
        <ol start="65" type="1">
          <li>
            <strong>Capital Legacy</strong> Let’s recap and make sure you
            understand the two gaps we have identified today.
          </li>
          <li>
            <strong>The Circle Back</strong> First, remember back in the FEGLI
            section we saw that you will have a significant gap of life
            insurance coverage after age 65? The free Basic Life Insurance
            provided by the government will drop by 75%, leaving you only
            $13,250 of death benefit for the rest of your life? Remember we also
            talked about the impact of Inflation. The costs associated with
            final expenses are only going to go up as we get older.
          </li>
          <li>
            Most of the Federal Employees that we meet care about these numbers
            the most! After all, this is what you will have when you are 90
            years old, and all your other temporary coverage goes away. Isn’t
            that what we care about? Making sure our loved ones aren’t left
            holding the bag?
          </li>
          <li>
            Secondly, you will have a “gap” or need for some emergency savings
            once you get to retirement. Remember, we’ve already seen that your
            income will drop significantly when you retire. It will be important
            for you to have some emergency funds set aside. This is your
            Rainy-Day Fund.
          </li>
          <li>
            <strong>Capital Legacy Benefit</strong> You and I will solve these
            two gaps most Federal Employees have with a program called Capital
            Legacy. Capital Legacy was designed exclusively for Federal
            Employees to solve these two primary issues.
          </li>
          <li>
            <strong>Hybrid Product</strong> Capital Legacy is what is called a
            hybrid product because it has two parts: Life Insurance & ROTH IRA
            accumulations and savings.
          </li>
          <li>
            <strong>Pay for Only the Life Insurance You Need</strong> the Life
            Insurance portion is cool. It provides you ONLY with the life
            insurance you need without paying for coverage you don’t. Makes
            sense, right? It also doesn’t go up in price like FEGLI.
          </li>
          <li>
            <strong>Save More Money</strong> The money you save on life
            insurance allows you to save even more money. The best part is that
            the money you save between now and retirement will play a
            significant role in your retirement!
          </li>
          <li>
            <strong>Cash Is King</strong> At retirement you no longer pay for
            life insurance because you will have accumulated enough savings.
            You’ve heard of Dave Ramsey, correct? Buy Term and Invest the
            Difference? This is exactly what he is referring to.
          </li>
          <li>
            <strong>Money Puts You Back in Control</strong> It’s the smartest
            way for you to solve the Life Insurance needs, rainy day fund, and
            Emergency Savings needs while also solving your cash and life
            insurance needs for tomorrow. Also remember the Roth IRA savings are
            tax-free; the tax-free money will be very important to you at
            retirement and offsetting the tax liability.
          </li>
          <li>
            <strong>Here’s How It Works</strong> This program is based on an
            allotment of $106 per pay period. Most people start this for $100 or
            even $150 per pay period. I’m recommending that you start this for
            just $106 per pay period.
            <ol type="a">
              <li>
                <strong>First Year</strong> $100 goes towards the life insurance
                locking in your coverage and locking in your rate. $5 goes
                towards the Roth IRA, and $1 goes to the bank that handles the
                transfer of your money from your paycheck to your Capital Legacy
                benefits.
              </li>
              <li>
                <strong>Second Year</strong> Beginning with the 2nd year, this
                splits to $50 life insurance, and $55 Roth IRA and $1 continues
                to go to the bank.
              </li>
              <li>
                <strong>Only the Life Insurance You Need</strong> Capital Legacy
                provides you with a Level Death benefit for the next 10 years.
                Starting the 11th year, your death benefit will slowly start
                decreasing. This is when you start saving more money. The money
                you save will automatically be added to your savings account.
              </li>
              <li>
                <strong>Save More Money</strong> At the beginning of year 11th
                year, you will receive back every dime paid into the policy for
                your first year. This money is credited to your ROTH. The money
                you save from the decreasing term will automatically start being
                added to the ROTH. Starting in year 11, your savings account
                will grow significantly.
              </li>
              <li>
                <strong>Furlough Rider</strong> you will have Furlough
                Protection. If you are told your position is being furloughed,
                you will have no premiums due for the first 6 months. This is
                HUGE
              </li>
              <li>
                <strong>Rainy Day Fund/Emergency Savings</strong> The best part
                of it all is that you are building a nest egg and safety net for
                you and your family.{" "}
                <strong>This money puts YOU BACK IN CONTROL.</strong> This money
                will be available to you between now and retirement. You can get
                cash penalty-free. Eliminates the future need for TSP loans,
                etc.
              </li>
              <li>
                <strong>No Risk Investment</strong> Capital Legacy has a
                Guaranteed interest rate. So regardless of what’s going on with
                the economy, you will always make at least ___% interest on your
                money for the rest of your life. This program is currently
                growing at 4%, and it has grown as high as 8 ¾ %. This gives you
                a great sense of security knowing that no matter what the stock
                markets are doing, you’ll always be making at least __%.
              </li>
              <li>
                <strong>Living Benefits</strong> There are also riders attached
                to the Capital Legacy policy at no cost to you. Serious
                illnesses such as cancer, terminal illnesses, etc. These “no
                cost” accelerated living benefits can prevent serious financial
                hardship.
              </li>
            </ol>
          </li>
          <li>
            <strong>The Close</strong>
            <ol type="a">
              <li>
                Based on your age and years of service, I recommend $106pp to at
                least get the ball rolling. We can always add more down the road
                if you’d like, however, the most important part of Capital
                Legacy is that it gets started.
              </li>
              <li>
                Do you have any questions about the program and how it works?
              </li>
              <li>
                Remember, based on what we saw from your paycheck, we know that
                we need to slide over the additional TSP 1% you are contributing
                to your TSP, plus make a slight adjustment to your FEDERAL TAX
                withholding.
              </li>
              <li>I’ll walk you through how to set everything up.</li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Summary;
