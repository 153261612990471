import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Pay from "./pay.helper";
import Pay2 from "./pay-s2.helper";
import Fers from "./fers.helper";
import Fers2 from "./fers-s2.helper";
import Welcome from "./welcome.helper";
import Welcome2 from "./welcome2.helper";
import Fegli from "./fegli.helper";
import Tsp from "./tsp.helper";
import Ss from "./ss.helper";
import Es from "./es.helper";
import Gap from "./gap.helper";
import Summary from "./summary.helper";
import Benefits from "./benefits.helper";
import "./planner.scss";

const Guide = () => {
  const [page, setPage] = useState("");

  const location = useLocation();

  useEffect(() => {
    setPage("");
    let path = location && location.pathname && location.pathname.split("/");
    let pageName = path && path[path.length - 1];

    if (pageName) {
      setPage(pageName);
    }
  }, []);

  return (
    <div className="planner-container">
      <div style={{ width: "100%", minHeight: "93vh" }}>
        <header className="header planner-title">
          <div>
            <h1 className="planner-title">
              Agent Compliance & Assistance Screen
            </h1>
          </div>
        </header>
        <div>{page == "pay" && <Pay />}</div>
        <div>{page == "pay-s2" && <Pay2 />}</div>
        <div>{page == "welcome" && <Welcome />}</div>
        <div>{page == "welcome2" && <Welcome2 />}</div>
        <div>{page == "fegli" && <Fegli />}</div>
        <div>{page == "fers" && <Fers />}</div>
        <div>{page == "fers-s2" && <Fers2 />}</div>
        <div>{page == "tsp" && <Tsp />}</div>
        <div>{page == "ss" && <Ss />}</div>
        <div>{page == "es" && <Es />}</div>
        <div>{page == "gap" && <Gap />}</div>
        <div>{page == "summary" && <Summary />}</div>
        <div>{page == "benefits" && <Benefits />}</div>
        <div>
          {page == "retirement" && (
            <div className="article-section">
              <div className="container">
                <p>
                  This screen does not contain follow-along content. Please
                  improvise based on training.
                </p>
              </div>
            </div>
          )}
        </div>
        <div>
          {page == "retirement-s2" && (
            <div className="article-section">
              <div className="container">
                <p>
                  This screen does not contain follow-along content. Please
                  improvise based on training.
                </p>
              </div>
            </div>
          )}
        </div>
        <div>
          {page == "more-about-you" && (
            <div className="article-section">
              <div className="container">
                <p>
                  This screen does not contain follow-along content. Please
                  improvise based on training.
                </p>
              </div>
            </div>
          )}
        </div>
        <div>
          {page == "more-about-you-s2" && (
            <div className="article-section">
              <div className="container">
                <p>
                  This screen does not contain follow-along content. Please
                  improvise based on training.
                </p>
              </div>
            </div>
          )}
        </div>
        <div>
          {page == "fers-annuity" && (
            <div className="article-section">
              <div className="container">
                <p>
                  This screen does not contain follow-along content. Please
                  improvise based on training.
                </p>
              </div>
            </div>
          )}
        </div>
        <div>
          {page == "tsp-ret" && (
            <div className="article-section">
              <div className="container">
                <p>
                  This screen does not contain follow-along content. Please
                  improvise based on training.
                </p>
              </div>
            </div>
          )}
        </div>
        <div>
          {page == "pensions-and-income" && (
            <div className="article-section">
              <div className="container">
                <p>
                  This screen does not contain follow-along content. Please
                  improvise based on training.
                </p>
              </div>
            </div>
          )}
        </div>
        <div>
          {page == "expenses" && (
            <div className="article-section">
              <div className="container">
                <p>
                  This screen does not contain follow-along content. Please
                  improvise based on training.
                </p>
              </div>
            </div>
          )}
        </div>
        <div>
          {page == "es-ret" && (
            <div className="article-section">
              <div className="container">
                <p>
                  This screen does not contain follow-along content. Please
                  improvise based on training.
                </p>
              </div>
            </div>
          )}
        </div>
        <div>
          {page == "tsp-options" && (
            <div className="article-section">
              <div className="container">
                <p>
                  This screen does not contain follow-along content. Please
                  improvise based on training.
                </p>
              </div>
            </div>
          )}
        </div>
        <div>
          {page == "conclusion" && (
            <div className="article-section">
              <div className="container">
                <p>
                  This screen does not contain follow-along content. Please
                  improvise based on training.
                </p>
              </div>
            </div>
          )}
        </div>

        {page == "client-survey" && (
          <div className="article-section">
            <div className="container">
              <p>
                This screen does not contain follow-along content. Please
                improvise based on training.
              </p>
            </div>
          </div>
        )}
        {page == "protection" && (
          <div className="article-section">
            <div className="container">
              <p>
                This screen does not contain follow-along content. Please
                improvise based on training.
              </p>
            </div>
          </div>
        )}
        {page == "email-report" && (
          <div className="article-section">
            <div className="container">
              <p>
                This screen does not contain follow-along content. Please
                improvise based on training.
              </p>
            </div>
          </div>
        )}

        {page == "congrats" && (
          <h1 className="green article-section">
            Planner Completed! Great Work!
          </h1>
        )}
      </div>
      <div style={{ width: "100%", background: "#333", zIndex: 1 }}>
        <p
          className="copy"
          style={{
            color: "#888",
            fontSize: "14px",
            margin: 0,
            lineHeight: "1.6em",
            padding: "7px",
            textAlign: "center",
          }}
        >
          © Copyright MyAgentCRM. All rights reserved.{" "}
          <a
            style={{ color: "#337ab7", TextDecoder: "none" }}
            href="https://myagentcrm.com/privacy/"
            itemprop="item"
          >
            <span itemprop="name">Privacy Policy</span>
          </a>{" "}
          |{" "}
          <a
            style={{ color: "#337ab7", TextDecoder: "none" }}
            href="https://myagentcrm.com/terms/"
            itemprop="item"
          >
            <span itemprop="name">Terms of Use</span>
          </a>
        </p>
      </div>
    </div>
  );
};

export default Guide;
