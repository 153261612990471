import React from "react";

const Pay = () => {
  return (
    <div className="article-section">
      <div className="container">
        <p>
          This screen does not contain follow-along content. Please improvise
          based on training.
        </p>
      </div>
    </div>
  );
};

export default Pay;
