import React, { useEffect, useState } from "react";
import { Card, Form, Button, Row, Col, Image } from "react-bootstrap";
import FederalEmployeeCostModal from "../../modal/federalEmployee-cost";
import {
  formatCalculatedValue,
  convertValue,
  roundUpToNextThousand,
  formatValue,
  get_cost_of_option_a,
} from "../../../services/helperService";
import Images from "../../CRMs/crm2.0";
import moment from "moment";
import { usePlannerContext } from "../../../contexts/planner.contexts";

const FegliForm = ({ validated, submit }) => {
  const {
    plannerData,
    handleBlur,
    onChangeHandler,
    handleKeyPress,
    handleFocus,
  } = usePlannerContext();

  const [formData, setformData] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (
      plannerData &&
      typeof plannerData === "object" &&
      Object.keys(plannerData).length > 0
    ) {
      const line8and2 = roundUpToNextThousand(plannerData.pay_s2_1);

      let roundedValue =
        convertValue(line8and2) + convertValue(plannerData["fegli_line3"]);
      let formatedValue = formatCalculatedValue(roundedValue);

      const factor = multifactor();
      let line7 = convertValue(formatedValue);

      line7 =
        convertValue(factor) &&
        convertValue(factor) * convertValue(formatedValue);

      const line2 = convertValue(line8and2);
      const factor2 = convertValue(plannerData["fegli_line9"]);
      const line10 = convertValue(factor2) && convertValue(factor2) * line2;

      const age60 = line10 ? (convertValue(line10) / 1000) * 0.867 : "0.00";

      const age65 = line10 ? (convertValue(line10) / 1000) * 1.04 : "0.00";

      const age70 = line10 ? (convertValue(line10) / 1000) * 1.863 : "0.00";

      const age80 = line10 ? (convertValue(line10) / 1000) * 6.24 : "0.00";

      const optionACheckbox =
        plannerData["checkboxOptionA"] === "1" ? true : false;

      let basicOptionAAmount =
        plannerData["checkboxBasicLife"] == "1"
          ? convertValue(formatedValue)
          : 0;

      basicOptionAAmount = optionACheckbox
        ? basicOptionAAmount + 10000
        : basicOptionAAmount;

      const basicOptionA = basicOptionAAmount;

      const fegli_basic_and_option_a_tomorrow = basicOptionA * 0.25;
      let fegli_s2_age_90_coverage = fegli_basic_and_option_a_tomorrow / 2;

      const optionCSpouce =
        convertValue(plannerData["selectOptionCMultiple"]) * 5000
          ? convertValue(plannerData["selectOptionCMultiple"]) * 5000
          : "0.00";
      const optionCChildren =
        convertValue(plannerData["selectOptionCMultiple"]) * 2500
          ? convertValue(plannerData["selectOptionCMultiple"]) * 2500
          : "0.00";
      const option_a = optionACheckbox ? "10,000.00" : "0.00";
      const total_life_insurance =
        convertValue(formatedValue) +
        convertValue(line7) +
        convertValue(line10) +
        convertValue(option_a);
      fegli_s2_age_90_coverage =
        fegli_s2_age_90_coverage +
        convertValue(plannerData["fegli_s2_private_sector_coverage"]);
      const payload = {
        ...plannerData,
        fegli_line4:
          plannerData["checkboxBasicLife"] == 1 ? formatedValue : "0.00",
        fegli_line2: plannerData["checkboxBasicLife"] == 1 ? line8and2 : "0.00",
        fegli_line5:
          plannerData["checkboxBasicLife"] == 1 ? formatedValue : "0.00",
        fegli_line6: factor,
        fegli_line7: formatValue(line7),
        fegli_line8: plannerData["checkboxBasicLife"] == 1 ? line8and2 : "0.00",
        fegli_line9: factor2,
        fegli_line10: formatValue(line10),
        age60OptionB: formatCalculatedValue(age60),
        age65OptionB: formatCalculatedValue(age65),
        age70OptionB: formatCalculatedValue(age70),
        age80OptionB: formatCalculatedValue(age80),
        fegli_basic_and_option_a:
          plannerData["checkboxBasicLife"] == 1
            ? formatCalculatedValue(basicOptionA)
            : "0.00",
        fegli_basic_and_option_a_tomorrow:
          plannerData["checkboxOptionA"] == 1 ||
          plannerData["checkboxBasicLife"] == 1
            ? formatCalculatedValue(fegli_basic_and_option_a_tomorrow)
            : "0.00",
        basic_life:
          plannerData["checkboxBasicLife"] == 1 ? formatedValue : "0.00",
        option_a,
        total_life_insurance:
          plannerData["checkboxBasicLife"] == 1
            ? formatCalculatedValue(total_life_insurance)
            : "0.00",
        optionCSpouce: formatCalculatedValue(optionCSpouce),
        optionCChildren: formatCalculatedValue(optionCChildren),
        fegli_s2_age_90_coverage:
          plannerData["checkboxOptionA"] == 1 ||
          plannerData["checkboxBasicLife"] == 1
            ? formatCalculatedValue(fegli_s2_age_90_coverage)
            : "0.00",
      };
      setformData(payload);
    }
    console.log(plannerData);
  }, [plannerData["fegli_line2"], formData["fegli_line2"]]);

  const handleFegliCalculation = (event) => {
    if (
      event.target.name == "fegli_line6" ||
      event.target.name == "fegli_line5"
    ) {
      let multiplyValue =
        event.target.name == "fegli_line6"
          ? formData.fegli_line5
          : formData.fegli_line6;
      let value = event.target.value;
      const line7 =
        value && multiplyValue
          ? convertValue(value) * convertValue(multiplyValue)
          : "0.00";

      setformData({
        ...formData,
        fegli_line7: formatCalculatedValue(line7),

        [event.target.name]: event.target.value,
      });
    } else if (
      event.target.name == "fegli_line8" ||
      event.target.name == "fegli_line9"
    ) {
      let multiplyValue =
        event.target.name == "fegli_line8"
          ? formData.fegli_line9
          : formData.fegli_line8;
      let value = event.target.value;
      const line10 =
        value && multiplyValue
          ? convertValue(value) * convertValue(multiplyValue)
          : "0.00";

      const age60 = formData["fegli_line10"]
        ? (convertValue(formData["fegli_line10"]) / 1000) * 0.876
        : "0.00";

      const age65 = formData["fegli_line10"]
        ? (convertValue(formData["fegli_line10"]) / 1000) * 1.4
        : "0.00";

      const age70 = formData["fegli_line10"]
        ? (convertValue(formData["fegli_line10"]) / 1000) * 1.863
        : "0.00";

      const age80 = formData["fegli_line10"]
        ? (convertValue(formData["fegli_line10"]) / 1000) * 3.9
        : "0.00";

      setformData({
        ...formData,
        fegli_line10: formatCalculatedValue(line10),
        age60OptionB: formatCalculatedValue(age60),
        age65OptionB: formatCalculatedValue(age65),
        age70OptionB: formatCalculatedValue(age70),
        age80OptionB: formatCalculatedValue(age80),

        [event.target.name]: event.target.value,
      });
    } else if (event.target.name === "fegli_basic_and_option_a") {
      let fegli_basic_and_option_a_tomorrow =
        convertValue(formData["fegli_basic_and_option_a"]) * 0.25;

      setformData({
        ...formData,
        fegli_basic_and_option_a_tomorrow: formatCalculatedValue(
          fegli_basic_and_option_a_tomorrow
        ),
        [event.target.name]: event.target.value,
      });
    } else if (
      event.target.name == "fegli_basic_and_option_a_tomorrow" ||
      event.target.name == "fegli_s2_private_sector_coverage"
    ) {
      let fegli_basic_and_option_a_tomorrow =
        event.target.name == "fegli_basic_and_option_a_tomorrow"
          ? convertValue(event.target.value)
          : convertValue(formData.fegli_basic_and_option_a_tomorrow);

      let fegli_s2_private_sector_coverage =
        event.target.name == "fegli_s2_private_sector_coverage"
          ? convertValue(event.target.value)
          : convertValue(formData.fegli_s2_private_sector_coverage);

      let fegli_s2_age_90_coverage = fegli_basic_and_option_a_tomorrow / 2;

      fegli_s2_age_90_coverage =
        convertValue(fegli_s2_age_90_coverage) +
        convertValue(fegli_s2_private_sector_coverage);

      setformData({
        ...formData,
        fegli_s2_age_90_coverage: formatCalculatedValue(
          fegli_s2_age_90_coverage
        ),
        [event.target.name]: event.target.value,
      });
    }
  };

  const multifactor = () => {
    const age = parseInt(moment().diff(plannerData["dob"], "years", true));
    let multifactor = 0;
    if (age <= 35) {
      multifactor = 1.0;
    }
    if (age >= 36) {
      multifactor = 0.9;
    }
    if (age >= 37) {
      multifactor = 0.8;
    }
    if (age >= 38) {
      multifactor = 0.7;
    }
    if (age >= 39) {
      multifactor = 0.6;
    }
    if (age >= 40) {
      multifactor = 0.5;
    }
    if (age >= 41) {
      multifactor = 0.4;
    }
    if (age >= 41) {
      multifactor = 0.3;
    }
    if (age >= 43) {
      multifactor = 0.2;
    }
    if (age >= 44) {
      multifactor = 0.1;
    }
    if (age >= 45) {
      multifactor = 0;
    }
    return multifactor;
  };

  const submitFormdata = (event) => {
    submit(event, formData);
  };

  return (
    <>
      <Card.Body className="fegli-section">
        <Form
          name="fegliForm"
          noValidate
          validated={validated}
          onSubmit={submitFormdata}
          className="employee-form"
        >
          <div className="text-center">
            <div className="head-title text-nowrap">
              FEDERAL EMPLOYEES GROUP LIFE INSURANCE (FEGLI)
            </div>
          </div>

          <Row>
            <Col md={6}>
              <div>
                <div className="fengli-title">
                  <span>BASIC LIFE </span>
                  <span className="text-black">
                    - ${plannerData["checkboxBasicLife"] == 1 ? "" : "0.00"}
                  </span>
                </div>
                <div className="sub-title">
                  <p>
                    Your basic insurance amount depends on your annual basic
                    pay. (Excludes COLA)
                  </p>
                </div>
                <table className="text-body">
                  <colgroup>
                    <col span="1" style={{ width: "10%" }} />
                    <col span="1" style={{ width: "2%" }} />
                    <col span="1" style={{ width: "58%" }} />
                    <col span="1" style={{ width: "30%" }} />
                  </colgroup>
                  <tbody className="text-start">
                    <tr>
                      <td>Line 1 </td>
                      <td>-</td>
                      <td>Your current basic pay</td>
                      <td>
                        <div className="input-wrapper">
                          $
                          <Form.Control
                            autoComplete="off"
                            className="invisible-input"
                            type="text"
                            name="fegli_line1"
                            value={formData.pay_s2_1}
                            disabled={true}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Line 2 </td>
                      <td>-</td>
                      <td>
                        If not an exact thousand,<br></br> round up to the next
                        thousand
                      </td>
                      <td>
                        <div className="input-wrapper">
                          $
                          <Form.Control
                            autoComplete="off"
                            className="invisible-input"
                            type="text"
                            name="fegli_line2"
                            value={formData["fegli_line2"]}
                            disabled={true}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Line 3 </td>
                      <td>-</td>
                      <td>Add $2,000</td>
                      <td>
                        <div className="input-wrapper">
                          ${" "}
                          <Form.Control
                            autoComplete="off"
                            className="invisible-input"
                            type="text"
                            name="fegli_line3"
                            value={formatValue(formData.fegli_line3)}
                            disabled={true}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Line 4 </td>
                      <td>-</td>

                      <td>
                        Total Insurance <br></br>(Add lines 2 and 3)
                      </td>
                      <td>
                        <div className="input-wrapper">
                          $
                          <Form.Control
                            autoComplete="off"
                            className="invisible-input"
                            type="text"
                            name="fegli_line4"
                            value={formData.fegli_line4}
                            disabled={true}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <div className="fengli-title">
                  <span>EXTRA BENEFIT </span>
                </div>
                <table className="text-body first-col-text-left text-center">
                  <tbody>
                    <tr>
                      <td>AGE</td>
                      <td>FACTOR</td>
                      <td>AGE</td>
                      <td>FACTOR</td>
                    </tr>
                    <tr>
                      <td>35 and under</td>
                      <td>1.0</td>
                      <td>40</td>
                      <td>.5</td>
                    </tr>
                    <tr>
                      <td>36</td>
                      <td>.9</td>
                      <td>41</td>
                      <td>.4</td>
                    </tr>
                    <tr>
                      <td>37</td>
                      <td>.8</td>
                      <td>42</td>
                      <td>.3</td>
                    </tr>
                    <tr>
                      <td>38</td>
                      <td>.7</td>
                      <td>43</td>
                      <td>.2</td>
                    </tr>
                    <tr>
                      <td>39</td>
                      <td>.6</td>
                      <td>44</td>
                      <td>.1</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>45</td>
                      <td>0</td>
                    </tr>
                  </tbody>
                </table>
                <table className="w-100">
                  <colgroup>
                    <col span="1" style={{ width: "15%" }} />
                    <col span="1" style={{ width: "55%" }} />
                    <col span="1" style={{ width: "30%" }} />
                  </colgroup>
                  <tbody>
                    <tr>
                      <td>Line 5 - </td>

                      <td>Basic Life Insurance (Line 4)</td>
                      <td>
                        <div className="input-wrapper">
                          ${" "}
                          <Form.Control
                            autoComplete="off"
                            className="input-box fw-bold"
                            type="text"
                            name="fegli_line5"
                            value={formData.fegli_line5}
                            onBlur={(e) => handleBlur(e, true, setformData)}
                            onKeyPress={(e) => handleKeyPress(e)}
                            onFocus={(event) => handleFocus(event, setformData)}
                            onChange={(event) => handleFegliCalculation(event)}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Line 6 - </td>

                      <td>Multiplication Factor</td>
                      <td>
                        <div className="input-wrapper">
                          X{" "}
                          <Form.Control
                            autoComplete="off"
                            className="input-box fw-bold"
                            type="text"
                            name="fegli_line6"
                            onKeyPress={(e) => handleKeyPress(e)}
                            onBlur={(e) => handleBlur(e, false, setformData)}
                            value={formData.fegli_line6}
                            onChange={(event) => handleFegliCalculation(event)}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Line 7 - </td>

                      <td>Total Extra Benefit</td>
                      <td>
                        <div className="input-wrapper">
                          $
                          <Form.Control
                            autoComplete="off"
                            className="input-box fw-bold"
                            type="text"
                            name="fegli_line7"
                            value={formData.fegli_line7}
                            onBlur={(e) => handleBlur(e, true, setformData)}
                            onKeyPress={(e) => handleKeyPress(e)}
                            onFocus={(event) => handleFocus(event, setformData)}
                            onChange={(event) =>
                              onChangeHandler(event, setformData)
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <div className="mt-3 fengli-title">
                  <span>
                    OPTION A - STANDARD{" "}
                    <i style={{ fontStyle: "italic" }}>($10,000)</i>
                  </span>
                  <span className="fegli-span">
                    {" "}
                    - ${get_cost_of_option_a(formData.age)} (Today)
                  </span>
                  <span className="fegli-span" style={{ float: "right" }}>
                    $0 (Age 65)
                  </span>
                </div>
                <table className="text-body first-col-text-left text-center fegli-box">
                  <colgroup>
                    <col span="1" style={{ width: "8%" }} />
                    <col span="1" style={{ width: "30%" }} />
                    <col span="1" style={{ width: "30%" }} />
                    <col span="1" style={{ width: "30%" }} />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th></th>
                      <th>AGE GROUP</th>
                      <th>BI-WEEKLY</th>
                      <th>MONTHLY</th>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>Under age 35</td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">0.20</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">0.43</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>35 through 39</td>
                      <td>
                        <div className="d-flex justify-content-center ">
                          $<div className="w-25 text-align-right">0.20</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center ">
                          $<div className="w-25 text-align-right">0.43</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>40 through 44</td>
                      <td>
                        <div className="d-flex justify-content-center ">
                          $<div className="w-25 text-align-right">0.30</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">0.65</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>45 through 49</td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">0.60</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">1.30</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>50 through 54</td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">1.00</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">2.17</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>55 through 59</td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">1.80</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">3.90</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>60+ through 64</td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">6.00</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">13.00</div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <div className="fengli-title">
                  <span>ACCIDENTAL DEATH AND DISMEMBERMENT</span>
                </div>
                <div className="sub-title">
                  <p>
                    Accidental death benefits are equal to your Basic life
                    insurance coverage. Accidental dismemberment benefits for
                    the loss of a hand, foot, or eye are equal to one half of
                    the Basic life insurance amount. The full amount is payable
                    if you lose any two of the three. This also applies to
                    Option A.
                  </p>
                </div>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <div className="mt-3 fengli-title">
                      <div className="d-flex justify-content-center">
                        <div className="text-nowrap">
                          OPTION B - ADDITIONAL{" "}
                        </div>

                        <div className="fegli-span text-nowrap">
                          {" "}
                          - ${formData["fegli_cost_option_b"]} (Today)
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <FederalEmployeeCostModal
                  showModal={showModal}
                  setShowModal={setShowModal}
                  age60={formData["age60OptionB"]}
                  age65={formData["age65OptionB"]}
                  age70={formData["age70OptionB"]}
                  age80={formData["age80OptionB"]}
                />
                <div className="sub-title d-flex">
                  <div>
                    <p>
                      Option B is one, two, three, four, or five times your
                      annual basic pay after rounding up to the next $1,000.
                    </p>
                  </div>
                  <div className="text-nowrap">
                    <Button
                      onClick={() => setShowModal(true)}
                      className="opt-B-btn w-100 p-0 m-0"
                    >
                      Click For Cost As You Age
                    </Button>
                  </div>
                </div>
                <table className="text-body">
                  <colgroup>
                    <col span="1" style={{ width: "10%" }} />
                    <col span="1" style={{ width: "2%" }} />
                    <col span="1" style={{ width: "58%" }} />
                    <col span="1" style={{ width: "30%" }} />
                  </colgroup>
                  <tbody>
                    <tr>
                      <td>Line 8 </td>
                      <td>-</td>

                      <td>Amount in Line 2</td>
                      <td>
                        <div className="input-wrapper">
                          ${" "}
                          <Form.Control
                            autoComplete="off"
                            className="input-box fw-bold"
                            type="text"
                            name="fegli_line8"
                            value={formData.fegli_line8}
                            onBlur={(e) => handleBlur(e, true, setformData)}
                            onKeyPress={(e) => handleKeyPress(e)}
                            onFocus={(event) => handleFocus(event, setformData)}
                            onChange={(event) => handleFegliCalculation(event)}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Line 9 </td>
                      <td>-</td>

                      <td>Multiple</td>
                      <td>
                        <div className="input-wrapper">
                          X{" "}
                          <Form.Control
                            autoComplete="off"
                            className="input-box fw-bold"
                            type="text"
                            name="fegli_line9"
                            onKeyPress={(e) => handleKeyPress(e)}
                            value={formData.fegli_line9}
                            onBlur={(e) => handleBlur(e, false, setformData)}
                            onChange={(event) => handleFegliCalculation(event)}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Line 10 </td>
                      <td>-</td>

                      <td>
                        Total Benefit<br></br>
                        (line 8 times line 9)
                      </td>
                      <td>
                        <div className="input-wrapper">
                          ${" "}
                          <Form.Control
                            autoComplete="off"
                            className="input-box fw-bold"
                            type="text"
                            name="fegli_line10"
                            value={formData.fegli_line10}
                            onBlur={(e) => handleBlur(e, true, setformData)}
                            onKeyPress={(e) => handleKeyPress(e)}
                            onFocus={(event) => handleFocus(event, setformData)}
                            onChange={(event) =>
                              onChangeHandler(event, setformData)
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Col>
            <Col md={6}>
              <div>
                <div className="fengli-title">
                  <span>WITHHOLDING PER $1,000 INSURANCE</span>
                </div>
                <table className="text-body first-col-text-left text-center fegli-box">
                  <col span="1" style={{ width: "8%" }} />
                  <col span="1" style={{ width: "30%" }} />
                  <col span="1" style={{ width: "30%" }} />
                  <col span="1" style={{ width: "30%" }} />
                  <tbody className="text-center">
                    <tr>
                      <th></th>
                      <th> AGE GROUP</th>
                      <th>BI-WEEKLY</th>
                      <th>MONTHLY</th>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>Under age 35</td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">0.02</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">.043</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td> 35 through 39</td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">0.02</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">0.043</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>40 through 44</td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">0.03</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">0.65</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td> 45 through 49</td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">0.06</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">0.13</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>50 through 54</td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">0.10</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">.217</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>55 through 59</td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">0.18</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">.39</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td> 60 through 64</td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">0.40</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">.867</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>65 through 69</td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">0.48</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">1.04</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>70 through 74</td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">0.86</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">1.863</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>75 through 79</td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">1.80</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">3.90</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>80+</td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">2.88</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">6.24</div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <div className="fengli-title ">
                  <span>OPTION C - FAMILY </span>
                  <span className="fegli-span">
                    - $
                    {formData["fegli_cost_option_c"]
                      ? formData["fegli_cost_option_c"]
                      : "0.00"}{" "}
                    (Today)
                  </span>
                  <span className="fegli-span" style={{ float: "right" }}>
                    ${" "}
                    {formData["selectOptionCMultiple"]
                      ? (
                          (6.13 *
                            parseFloat(formData["selectOptionCMultiple"]) *
                            12) /
                          26
                        ).toFixed(2)
                      : "0.00"}{" "}
                    (Age 65)
                  </span>
                </div>
                <div className="sub-title">
                  <p>
                    Option C covers eligible family members in multiples up to
                    five (5) times per unit. Each unit is worth $5,000 for
                    spouse and $2,500 for children, including adopted children
                    and stepchildren. Children must be under age 22, or if 22 or
                    over, must be incapable of self-support due to a mental or
                    physical disability. Costs below are per unit of coverage.
                  </p>
                </div>
                <table className="text-body first-col-text-left text-center fegli-box">
                  <col span="1" style={{ width: "8%" }} />
                  <col span="1" style={{ width: "30%" }} />
                  <col span="1" style={{ width: "30%" }} />
                  <col span="1" style={{ width: "30%" }} />
                  <tbody className="text-center">
                    <tr>
                      <th></th>
                      <th> AGE GROUP</th>
                      <th>BI-WEEKLY</th>
                      <th>MONTHLY</th>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>Under age 35</td>
                      <td>
                        <div className="d-flex justify-content-center ">
                          $<div className="w-25 text-align-right">0.20</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">0.43</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td> 35 through 39</td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">0.24</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">0.52</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>40 through 44</td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">0.37</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">0.80</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td> 45 through 49</td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">0.53</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">1.15</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>50 through 54</td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">0.83</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">1.80</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>55 through 59</td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">1.33</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">2.88</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td> 60 through 64</td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">2.43</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">5.27</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>65 through 69</td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">2.83</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">6.13</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>70 through 74</td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">3.83</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">8.30</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>75 through 79</td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">5.76</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">12.48</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>80+</td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">7.80</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          $<div className="w-25 text-align-right">16.9</div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="sub-title">
                  <p>The above Option C cost is for 1 unit of coverage.</p>
                </div>
                <ul
                  style={{
                    fontSize: "14px",
                    marginBottom: "15px",
                    listStyleType: "disc",
                  }}
                >
                  <li>Coverage may be added within 60 days of a QLE.</li>
                </ul>
              </div>
              <div>
                <div className="fengli-title">
                  <span>ELIGIBLE FAMILY MEMBERS</span>
                </div>
                <div className="sub-title">
                  <p>
                    Present spouse and unmarried dependent children, including
                    an adopted child, a stepchild, or a recognized natural child
                    who is either living with you or is receiving regular and
                    substantial support. To be covered a child must be under 22
                    years of age. If the dependent is 22 years of age or older,
                    he or she must be incapable of self support due to mental or
                    physical disability which existed prior to the dependent
                    becoming 22 years of age.
                  </p>
                </div>
              </div>
              <div className="fegli-Eligible-member-text">
                Information in this guide is based on information from the
                Office of Personnel Management,{" "}
                <a className="fegli-A-btn" href="https://www.opm.gov/">
                  www.opm.gov
                </a>
              </div>
            </Col>
          </Row>

          <Row className="fegli-container">
            <Col md={6} className="side-section">
              <div>
                <div className="d-flex justify-content-center align-items-end">
                  <Col md={7}>
                    <div>
                      <div className="main-title">
                        <h6>Basic and Option A (TODAY)</h6>
                      </div>
                    </div>
                  </Col>
                  <Col md={5}>
                    {" "}
                    <div className="input-wrapper mt-1">
                      <b className="fers-b">$</b>
                      <Form.Control
                        autoComplete="off"
                        className="input-box fegli-Continue"
                        type="text"
                        name="fegli_basic_and_option_a"
                        value={formData.fegli_basic_and_option_a}
                        onBlur={(e) => handleBlur(e, true, setformData)}
                        onKeyPress={(e) => handleKeyPress(e)}
                        onFocus={(event) => handleFocus(event, setformData)}
                        onChange={(event) =>
                          onChangeHandler(event, setformData)
                        }
                      />
                    </div>
                  </Col>
                </div>

                <div className="sub-title">
                  <p>
                    The amount of life insurance decreases at age 65 or at
                    retirement, whichever is later, at a rate of 2% a month
                    until 25% of the amount you had at time of retirement is
                    reached. This also applies for Option A.
                  </p>
                </div>
              </div>
              <div className="mt-3">
                <div>
                  <Row className="d-flex  align-items-end">
                    <Col md={7}>
                      {" "}
                      <div
                        className="main-title "
                        style={{ textAlign: "right" }}
                      >
                        <h6>Basic and Option A (TOMORROW)</h6>
                      </div>
                    </Col>
                    <Col md={5}>
                      <div className="input-wrapper mt-1">
                        <b className="fers-b">$</b>
                        <Form.Control
                          autoComplete="off"
                          type="text"
                          className="input-box fegli-Continue"
                          name="fegli_basic_and_option_a_tomorrow"
                          value={formData.fegli_basic_and_option_a_tomorrow}
                          onBlur={(e) => handleBlur(e, true, setformData)}
                          onKeyPress={(e) => handleKeyPress(e)}
                          onFocus={(event) => handleFocus(event, setformData)}
                          onChange={(event) => handleFegliCalculation(event)}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>

                <Row className="d-flex  align-items-end">
                  <Col md={7}>
                    {" "}
                    <div className="main-title" style={{ textAlign: "right" }}>
                      <h6 style={{ color: "#000" }}>Private Sector Coverage</h6>
                    </div>
                  </Col>

                  <Col md={5}>
                    <div className="input-wrapper mt-1">
                      <b className="fers-b">$</b>
                      <Form.Control
                        autoComplete="off"
                        className="input-box fegli-Continue"
                        name="fegli_s2_private_sector_coverage"
                        type="text"
                        value={formData.fegli_s2_private_sector_coverage}
                        onBlur={(e) => handleBlur(e, true, setformData)}
                        onKeyPress={(e) => handleKeyPress(e)}
                        onFocus={(event) => handleFocus(event, setformData)}
                        onChange={(event) => handleFegliCalculation(event)}
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="d-flex  align-items-end mt-1">
                  <Col md={7}>
                    {" "}
                    <div className="main-title" style={{ textAlign: "right" }}>
                      <h6 style={{ color: "#08497e" }}>
                        Age 90 "Real Feel" Value *
                      </h6>
                    </div>
                  </Col>

                  <Col md={5}>
                    <div className="input-wrapper ">
                      <b className="fers-b">$</b>
                      <Form.Control
                        autoComplete="off"
                        className="input-box fegli-Continue"
                        name="fegli_s2_age_90_coverage"
                        type="text"
                        value={formData.fegli_s2_age_90_coverage}
                        onBlur={(e) => handleBlur(e, true, setformData)}
                        onKeyPress={(e) => handleKeyPress(e)}
                        onFocus={(event) => handleFocus(event, setformData)}
                        onChange={(event) =>
                          onChangeHandler(event, setformData)
                        }
                      />
                    </div>
                  </Col>
                </Row>

                <div className="sub-title">
                  <p>
                    * Real Feel Value is the projected purchase power of the
                    death benefit from Age 65 to Age 90 factoring in inflation
                    at roughly 3% annually.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6} className="side-section">
              <Image className="dollar-image" src={Images.doller75} />
            </Col>
          </Row>
          <div className="text-center">
            <div className="Tsp2-heading" style={{ margin: "20px 0px" }}>
              FEGLI - RETIREMENT or AGE 65
            </div>
          </div>
          <Row>
            <Col md={6}>
              <div>
                <div>
                  <div className="main-title">
                    <h6>Option B & C</h6>
                  </div>
                </div>
                <div>
                  <div className="sub-title">
                    <p>
                      At age 65 or retirement, whichever is later, an additional
                      2% reduction of the original amount per month for 50
                      months, at which point coverage ceases. You may elect to
                      continue this coverage into retirement, but at a full
                      premium which increases dramatically as you get older.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <div>
                  <div className="main-title">
                    <h6>Retirement Options</h6>
                  </div>
                </div>
                <div>
                  <div className="sub-title">
                    <b>Basic Insurance</b>
                  </div>
                  <div className="sub-title">
                    <div>
                      <ol>
                        <li style={{ margin: 0 }}>
                          75% reduction begins at age 65, at a cost of $ 0.3467
                          per thousand per month until age 65; no cost
                          thereafter.
                        </li>
                        <li style={{ margin: 0 }}>
                          50% reduction, at a cost of $1.0967 per thousand per
                          month until age 65, $0.75 per thousand, per month
                          thereafter.
                        </li>
                        <li style={{ margin: 0 }}>
                          No reduction, at a cost of $2.5967 per thousand per
                          month until age 65, $2.25 per thousand per month
                          thereafter.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={6}>
              <Image className="dollar-image" src={Images.doller100} />
            </Col>
            <Col md={12}>
              <div className="mt-3 sub-title">
                <strong>Option A</strong> continues at full face amount at
                standard amount until age 65, then begins reducing at 2% per
                month until reaching 25% of face amount, at no cost.
              </div>
              <div className="mt-3 mb-3 sub-title">
                <strong>Option B &amp; C</strong> If you elect Full Reduction,
                withholdings stop the month after your 65th birthday, and the
                premiums cease while face amount is reducing. If you elect No
                Reduction, withholdings for your appropriate age band continue
                after age 65 (unless you cancel coverage or change to Full
                Reduction).
              </div>
            </Col>
          </Row>
          <Row className="fegli-container">
            <Col md={4} className="mt-3 side-section">
              <div>
                <div className="main-title">
                  <h6>Total Life Insurance</h6>
                </div>
                <div className="sub-title text-nowrap d-flex justify-content-between ">
                  <div>Basic Life</div>
                  <div className="input-wrapper mt-1 input-end">
                    <b>$</b>
                    <Form.Control
                      autoComplete="off"
                      className="input-box fegli-input-small"
                      type="text"
                      name="basic_life"
                      value={formData.basic_life}
                      onBlur={(e) => handleBlur(e, true, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      onFocus={(event) => handleFocus(event, setformData)}
                      onChange={(event) => onChangeHandler(event, setformData)}
                    />
                  </div>
                </div>
                <div className="sub-title text-nowrap d-flex justify-content-between">
                  <div>Extra Benefit</div>
                  <div className="input-wrapper mt-1 input-end">
                    <b>$</b>
                    <Form.Control
                      autoComplete="off"
                      className="input-box fegli-input-small"
                      type="text"
                      name="fegli_line7"
                      value={formData.fegli_line7}
                      onBlur={(e) => handleBlur(e, true, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      onFocus={(event) => handleFocus(event, setformData)}
                      onChange={(event) => onChangeHandler(event, setformData)}
                    />
                  </div>
                </div>
                <div className="sub-title text-nowrap d-flex justify-content-between">
                  <div>Option A ($10,000)</div>
                  <div className="input-wrapper mt-1 input-end">
                    <b>$</b>
                    <Form.Control
                      autoComplete="off"
                      className="input-box fegli-input-small"
                      type="text"
                      name="option_a"
                      value={formData.option_a}
                      onBlur={(e) => handleBlur(e, true, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      onFocus={(event) => handleFocus(event, setformData)}
                      onChange={(event) => onChangeHandler(event, setformData)}
                    />
                  </div>
                </div>
                <div className="sub-title text-nowrap d-flex justify-content-between">
                  <div>Option B - Additional</div>
                  <div className="input-wrapper mt-1 input-end">
                    <b>$</b>
                    <Form.Control
                      autoComplete="off"
                      className="input-box fegli-input-small"
                      type="text"
                      name="fegli_line10"
                      value={formData.fegli_line10}
                      onBlur={(e) => handleBlur(e, true, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      onFocus={(event) => handleFocus(event, setformData)}
                      onChange={(event) => onChangeHandler(event, setformData)}
                    />
                  </div>
                </div>
                <div className="sub-title text-nowrap d-flex justify-content-between">
                  <div>Total Life Insurance</div>
                  <div className="input-wrapper mt-1 input-end">
                    <b>$</b>
                    <Form.Control
                      autoComplete="off"
                      className="input-box fegli-input-small"
                      type="text"
                      name="total_life_insurance"
                      value={formData.total_life_insurance}
                      onBlur={(e) => handleBlur(e, true, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      onFocus={(event) => handleFocus(event, setformData)}
                      onChange={(event) => onChangeHandler(event, setformData)}
                    />
                  </div>
                </div>
                <div className="sub-title text-nowrap d-flex justify-content-between">
                  <div>Option C - Spouse</div>
                  <div className="input-wrapper mt-1 input-end">
                    <b>$</b>
                    <Form.Control
                      autoComplete="off"
                      className="input-box fegli-input-small"
                      type="text"
                      name="optionCSpouce"
                      value={formData.optionCSpouce}
                      onBlur={(e) => handleBlur(e, true, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      onFocus={(event) => handleFocus(event, setformData)}
                      onChange={(event) => onChangeHandler(event, setformData)}
                    />
                  </div>
                </div>
                <div className="sub-title  d-flex justify-content-between">
                  <div className="d-flex">
                    <span style={{ visibility: "hidden", width: "63px" }}>
                      Option C{" "}
                    </span>
                    <span> - Children (Under age 22 or disabled)</span>
                  </div>
                  <div className="input-wrapper mt-1 input-end">
                    <b>$</b>
                    <Form.Control
                      autoComplete="off"
                      className="input-box fegli-input-small fegli-child-input"
                      type="text"
                      name="optionCChildren"
                      value={formData.optionCChildren}
                      onBlur={(e) => handleBlur(e, true, setformData)}
                      onKeyPress={(e) => handleKeyPress(e)}
                      onFocus={(event) => handleFocus(event, setformData)}
                      onChange={(event) => onChangeHandler(event, setformData)}
                    />
                  </div>
                </div>
              </div>

              <div className=" p-1 mt-2" style={{ border: "1px solid #333" }}>
                <div className="p-3">
                  <div className="main-title">
                    <h6>Option B monthly Premium</h6>
                  </div>
                  <div className="sub-title d-flex text-nowrap  justify-content-between align-items-center">
                    <div>Age 60</div>
                    <div
                      className="input-wrapper"
                      style={{ marginLeft: "20px" }}
                    >
                      <b>$</b>
                      <Form.Control
                        autoComplete="off"
                        className="input-box fegli-input-small w-100"
                        type="text"
                        name="age60OptionB"
                        value={formData.age60OptionB}
                        onBlur={(e) => handleBlur(e, true, setformData)}
                        onKeyPress={(e) => handleKeyPress(e)}
                        onFocus={(event) => handleFocus(event, setformData)}
                        onChange={(event) =>
                          onChangeHandler(event, setformData)
                        }
                      />
                    </div>
                  </div>
                  <div className="sub-title text-nowrap d-flex justify-content-between align-items-center">
                    <div>Age 65</div>
                    <div
                      className="input-wrapper"
                      style={{ marginLeft: "20px" }}
                    >
                      <b>$</b>
                      <Form.Control
                        autoComplete="off"
                        className="input-box fegli-input-small w-100"
                        type="text"
                        name="age65OptionB"
                        value={formData.age65OptionB}
                        onBlur={(e) => handleBlur(e, true, setformData)}
                        onKeyPress={(e) => handleKeyPress(e)}
                        onFocus={(event) => handleFocus(event, setformData)}
                        onChange={(event) =>
                          onChangeHandler(event, setformData)
                        }
                      />
                    </div>
                  </div>
                  <div className="sub-title text-nowrap d-flex justify-content-between align-items-center">
                    <div>Age 70</div>
                    <div
                      className="input-wrapper"
                      style={{ marginLeft: "20px" }}
                    >
                      <b>$</b>
                      <Form.Control
                        autoComplete="off"
                        className="input-box fegli-input-small w-100"
                        type="text"
                        name="age70OptionB"
                        value={formData.age70OptionB}
                        onBlur={(e) => handleBlur(e, true, setformData)}
                        onKeyPress={(e) => handleKeyPress(e)}
                        onFocus={(event) => handleFocus(event, setformData)}
                        onChange={(event) =>
                          onChangeHandler(event, setformData)
                        }
                      />
                    </div>
                  </div>
                  <div className="sub-title text-nowrap d-flex justify-content-between align-items-center">
                    <div>Age 80</div>
                    <div
                      className="input-wrapper"
                      style={{ marginLeft: "20px" }}
                    >
                      <b>$</b>
                      <Form.Control
                        autoComplete="off"
                        className="input-box fegli-input-small w-100"
                        type="text"
                        name="age80OptionB"
                        value={formData.age80OptionB}
                        onBlur={(e) => handleBlur(e, true, setformData)}
                        onKeyPress={(e) => handleKeyPress(e)}
                        onFocus={(event) => handleFocus(event, setformData)}
                        onChange={(event) =>
                          onChangeHandler(event, setformData)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={8} className="side-section">
              <div style={{ border: "1px solid #333" }}>
                <div className="p-3">
                  <div className="main-title">
                    <h6>Your Option</h6>
                  </div>

                  <div className="sub-title ">
                    <p>
                      Your Option The chart below will explain which options you
                      currently have.
                    </p>

                    <Row className="mt-2 fegliForm">
                      <Col md={3}>
                        <div>A = Ineligible</div>

                        <div>
                          <strong>B = No Benefits</strong>
                        </div>

                        <div>C = BL</div>

                        <div>D = BL, A</div>

                        <div>E = BL, C</div>

                        <div>F = BL, A, C</div>

                        <div>G = BL, B-1</div>
                      </Col>
                      <Col md={3}>
                        <div>H = BL, A, B-1</div>

                        <div>I = BL, B-1, C</div>

                        <div>J = BL, A, B-1, C</div>

                        <div>K = BL, B-2</div>

                        <div>L = BL, A, B-2</div>

                        <div>M = BL, B-2, C</div>

                        <div>N = BL, A, B-2, C</div>
                      </Col>
                      <Col md={3}>
                        <div>9 = BL, B-3</div>

                        <div>P = BL, A, B-3</div>

                        <div>Q = BL, B-3, C</div>

                        <div>R = BL, A, B-3, C</div>

                        <div>S = BL, B-4</div>

                        <div>T = BL, A, B-4</div>

                        <div>U = BL, B-4, C</div>
                      </Col>
                      <Col md={3}>
                        <div>V = BL, A, B-4, C</div>

                        <div>W = BL, B-5</div>

                        <div>X = BL, A, B-5</div>

                        <div>Y = BL, B-5, C</div>

                        <div>Z = BL, A, B-5, C</div>
                      </Col>
                    </Row>
                  </div>
                  <div className="mt-2 sub-title text-nowrap">
                    <i>For USPS only,no code = Basic only</i>
                  </div>
                </div>
              </div>
              <div className=" p-1 mt-2" style={{ border: "1px solid #333" }}>
                <div className="main-title">
                  <h6>Notes</h6>
                </div>
                <div>
                  <Form.Text
                    className="form-text-area"
                    as="textarea"
                    name="fegli_s2_notes"
                    rows={6}
                    value={formData.fegli_s2_notes}
                    onChange={(event) => onChangeHandler(event, setformData)}
                  />
                </div>
              </div>
            </Col>
          </Row>

          <div className="btn-wrapper">
            <div className="text-center mt-4">
              <button className="btn-next btn question-next" type="submit">
                CONTINUE
              </button>
            </div>
          </div>
        </Form>
      </Card.Body>
    </>
  );
};

export default FegliForm;
