import React, { useEffect } from "react";

const HubspotContactForm = ({ handleFormSub }) => {
  let referralBoxes = 3;

  useEffect(() => {
    const userData = { ...JSON.parse(localStorage.getItem("access")) };

    const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          region: "na1",
          portalId: "39590313",
          formId: "b8749e4f-364a-4628-847c-ab66819000d5",
          target: "#hubspotForm",
          inlineMessage: "Form Submitted Successfully.",
          onFormSubmit: function (form) {
            const inputElement = form.querySelector(
              "input[name='survey_agent']"
            );
            if (inputElement) {
              inputElement.value = userData.user_email;
            }
            populateEmailForFields(form);
          },
          onFormSubmitted: function (form) {
            handleFormSub();
          },
          onFormReady: function (form) {
            applyCSS(form);
            buttonListen(form);
          },
        });
      }
    });
  }, []);

  const applyCSS = (form) => {
    // Select the #another-referral-button and apply styles
    const button = form.querySelector("#another-referral-button");
    button.style.cursor = "pointer";

    // Add hover effect using JavaScript
    button.addEventListener("mouseover", function () {
      button.style.textDecoration = "underline";
    });
    button.addEventListener("mouseout", function () {
      button.style.textDecoration = "none";
    });

    // List of referral elements to hide
    const referralSelectors = [
      ".hs_referral_4___first_name",
      ".hs_referral_4___last_name",
      ".hs_referral_4___email",
      ".hs_referral_4___phone",
      ".hs_referral_5___first_name",
      ".hs_referral_5___last_name",
      ".hs_referral_5___email",
      ".hs_referral_5___phone",
      ".hs_referral_6___first_name",
      ".hs_referral_6___last_name",
      ".hs_referral_6___email",
      ".hs_referral_6___phone",
      ".hs_referral_7___first_name",
      ".hs_referral_7___last_name",
      ".hs_referral_7___email",
      ".hs_referral_7___phone",
      ".hs_referral_8___first_name",
      ".hs_referral_8___last_name",
      ".hs_referral_8___email",
      ".hs_referral_8___phone",
      ".hs_referral_9___first_name",
      ".hs_referral_9___last_name",
      ".hs_referral_9___email",
      ".hs_referral_9___phone",
      ".hs_referral_10___first_name",
      ".hs_referral_10___last_name",
      ".hs_referral_10___email",
      ".hs_referral_10___phone",
    ];

    // Apply styles to each referral element
    referralSelectors.forEach((selector) => {
      form.querySelectorAll(selector).forEach((el) => {
        el.style.display = "none"; // Apply any specific inline styles here
      });
    });

    // Hide elements with class .hide-hr
    form.querySelectorAll(".hide-hr").forEach((el) => {
      el.style.display = "none";
    });
  };

  function serializeForm(form) {
    const formData = new FormData(form);
    const formObject = {};

    for (const [key, value] of formData.entries()) {
      formObject[key] = value;
    }

    return formObject;
  }

  function buttonListen(form) {
    let button = form.querySelector("#another-referral-button");
    button.addEventListener("click", function addReferral(e) {
      let nextBoxFirst = form.querySelectorAll(".hs_referral_4___first_name");
      let nextBoxLast = form.querySelectorAll(".hs_referral_4___last_name");
      let nextBoxPhone = form.querySelectorAll(".hs_referral_4___phone");
      let nextBoxEmail = form.querySelectorAll(".hs_referral_4___email");
      let nextBoxHR = form.querySelector("#hr1");
      if (referralBoxes == 3) {
        nextBoxFirst[0].style.display = "inline-block";
        nextBoxLast[0].style.display = "inline-block";
        nextBoxPhone[0].style.display = "inline-block";
        nextBoxEmail[0].style.display = "inline-block";
        nextBoxHR.style.display = "block";
        referralBoxes = referralBoxes + 1;
      } else if (referralBoxes == 4) {
        nextBoxFirst = form.querySelectorAll(".hs_referral_5___first_name");
        nextBoxLast = form.querySelectorAll(".hs_referral_5___last_name");
        nextBoxPhone = form.querySelectorAll(".hs_referral_5___phone");
        nextBoxEmail = form.querySelectorAll(".hs_referral_5___email");
        nextBoxHR = form.querySelector("#hr2");
        nextBoxFirst[0].style.display = "inline-block";
        nextBoxLast[0].style.display = "inline-block";
        nextBoxPhone[0].style.display = "inline-block";
        nextBoxEmail[0].style.display = "inline-block";
        nextBoxHR.style.display = "block";
        referralBoxes = referralBoxes + 1;
      } else if (referralBoxes == 5) {
        nextBoxFirst = form.querySelectorAll(".hs_referral_6___first_name");
        nextBoxLast = form.querySelectorAll(".hs_referral_6___last_name");
        nextBoxPhone = form.querySelectorAll(".hs_referral_6___phone");
        nextBoxEmail = form.querySelectorAll(".hs_referral_6___email");
        nextBoxHR = form.querySelector("#hr3");
        nextBoxFirst[0].style.display = "inline-block";
        nextBoxLast[0].style.display = "inline-block";
        nextBoxPhone[0].style.display = "inline-block";
        nextBoxEmail[0].style.display = "inline-block";
        nextBoxHR.style.display = "block";
        referralBoxes = referralBoxes + 1;
      } else if (referralBoxes == 6) {
        nextBoxFirst = form.querySelectorAll(".hs_referral_7___first_name");
        nextBoxLast = form.querySelectorAll(".hs_referral_7___last_name");
        nextBoxPhone = form.querySelectorAll(".hs_referral_7___phone");
        nextBoxEmail = form.querySelectorAll(".hs_referral_7___email");
        nextBoxHR = form.querySelector("#hr4");
        nextBoxFirst[0].style.display = "inline-block";
        nextBoxLast[0].style.display = "inline-block";
        nextBoxPhone[0].style.display = "inline-block";
        nextBoxEmail[0].style.display = "inline-block";
        nextBoxHR.style.display = "block";
        referralBoxes = referralBoxes + 1;
      } else if (referralBoxes == 7) {
        nextBoxFirst = form.querySelectorAll(".hs_referral_8___first_name");
        nextBoxLast = form.querySelectorAll(".hs_referral_8___last_name");
        nextBoxPhone = form.querySelectorAll(".hs_referral_8___phone");
        nextBoxEmail = form.querySelectorAll(".hs_referral_8___email");
        nextBoxHR = form.querySelector("#hr5");
        nextBoxFirst[0].style.display = "inline-block";
        nextBoxLast[0].style.display = "inline-block";
        nextBoxPhone[0].style.display = "inline-block";
        nextBoxEmail[0].style.display = "inline-block";
        nextBoxHR.style.display = "block";
        referralBoxes = referralBoxes + 1;
      } else if (referralBoxes == 8) {
        nextBoxFirst = form.querySelectorAll(".hs_referral_9___first_name");
        nextBoxLast = form.querySelectorAll(".hs_referral_9___last_name");
        nextBoxPhone = form.querySelectorAll(".hs_referral_9___phone");
        nextBoxEmail = form.querySelectorAll(".hs_referral_9___email");
        nextBoxHR = form.querySelector("#hr6");
        nextBoxFirst[0].style.display = "inline-block";
        nextBoxLast[0].style.display = "inline-block";
        nextBoxPhone[0].style.display = "inline-block";
        nextBoxEmail[0].style.display = "inline-block";
        nextBoxHR.style.display = "block";
        referralBoxes = referralBoxes + 1;
      } else if (referralBoxes == 9) {
        nextBoxFirst = form.querySelectorAll(".hs_referral_10___first_name");
        nextBoxLast = form.querySelectorAll(".hs_referral_10___last_name");
        nextBoxPhone = form.querySelectorAll(".hs_referral_10___phone");
        nextBoxEmail = form.querySelectorAll(".hs_referral_10___email");
        nextBoxFirst[0].style.display = "inline-block";
        nextBoxLast[0].style.display = "inline-block";
        nextBoxPhone[0].style.display = "inline-block";
        nextBoxEmail[0].style.display = "inline-block";
        button.style.display = "none";
        referralBoxes = referralBoxes + 1;
      } else {
      }
    });
  }

  function populateEmailForFields(form) {
    let parentDiv = form.querySelectorAll(".form-columns-2");

    let inputsArr = [];
    parentDiv.forEach((d) => inputsArr.push(d.querySelectorAll("input")));

    let inputsArranged = [];
    for (let i = 0; i < inputsArr.length; ) {
      let tempArr = [
        inputsArr[i][0],
        inputsArr[i][1],
        inputsArr[i + 1][0],
        inputsArr[i + 1][1],
      ];
      inputsArranged.push(tempArr);

      i += 2;
    }

    inputsArranged.forEach((d, index) => {
      if (d[0].value != "" || d[1].value != "" || d[2].value != "") {
        if (d[3].value == "") {
          d[3].value = Date.now() + "" + index + "@mfbhelp.com";
        }
      }
    });
  }

  return (
    <div>
      <div id="hubspotForm"></div>
    </div>
  );
};

export default HubspotContactForm;
