import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Card,
  FloatingLabel,
  Form,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { usePlannerContext } from "../../../contexts/planner.contexts";
import { showPdfStatus } from "../../../services/helperService";

const PaystubForm = ({ submit, validated }) => {
  const {
    plannerData,
    handleDateChange,
    handleRadioButton,
    onChangeHandler,
    handleOnchangeCheckbox,
    Tab,
  } = usePlannerContext();

  const [formData, setformData] = useState({});

  useEffect(() => {
    if (
      plannerData &&
      typeof plannerData === "object" &&
      Object.keys(plannerData).length > 0
    ) {
      setformData(plannerData);
    }
  }, [plannerData]);

  const handleDobChange = async (date) => {
    const validateDate = moment(date);
    if (validateDate && validateDate.isValid()) {
      let age = moment().diff(validateDate, "years", true);
      age = age.toFixed(2);
      let pdfStatus = showPdfStatus(date, Tab);

      setformData({
        ...formData,
        dob: moment(validateDate),
        pdfStatus,
        age,
      });
    }
  };

  const submitFormdata = (event) => {
    submit(event, formData);
  };

  return (
    <>
      <Card.Body className="paystub-section">
        <div className="text-center">
          <div className="head-title">Paystub Review</div>
        </div>
        <div className="mt-3">
          <div className="paystub-title">Name & DOB</div>
          <Form
            name="paystubForm"
            className="paystub-text"
            noValidate
            validated={validated}
            onSubmit={submitFormdata}
          >
            <FloatingLabel
              controlId="floatingInput"
              label="First Name"
              className="my-3 floatingInput "
            >
              <Form.Control
                autoComplete="off"
                type="text"
                className="required-input"
                name="firstname"
                onChange={(event) => onChangeHandler(event, setformData)}
                placeholder="First Name"
                value={formData.firstname}
                isInvalid={validated && !formData.firstname}
                required
              />
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingInput"
              label="Last Name"
              className="mb-2 floatingInput "
            >
              <Form.Control
                autoComplete="off"
                type="text"
                className="required-input"
                name="lastname"
                onChange={(event) => onChangeHandler(event, setformData)}
                value={formData.lastname}
                placeholder="Last Name"
                isInvalid={validated && !formData.lastname}
                required
              />
            </FloatingLabel>
            <div className="d-flex align-items-center mb-3">
              <div className="mx-2">Gender:</div>
              <div className="mx-2 d-flex">
                <div className="mx-2">
                  <input
                    id="male"
                    type="radio"
                    name="gender"
                    className={`mt-3`}
                    value="male"
                    checked={formData.gender == "male"}
                    onChange={(event) => {
                      handleOnchangeCheckbox(event, "male", setformData);
                    }}
                  />{" "}
                  <label htmlFor="male">Male</label>
                </div>
                <div className="mx-2">
                  <input
                    id="female"
                    type="radio"
                    name="gender"
                    className={`mt-3`}
                    checked={formData.gender == "female"}
                    onChange={(event) => {
                      handleOnchangeCheckbox(event, "female", setformData);
                    }}
                  />{" "}
                  <label htmlFor="female">Female</label>
                </div>
              </div>
            </div>

            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Date of Birth"
                maxDate={moment()}
                value={formData["dob"] ? formData["dob"] : null}
                className={`required-input normal-picker w-100 mb-3 ${
                  validated && !formData.dob ? " is-invalid" : ""
                } `}
                slotProps={{
                  textField: {
                    required: true,
                  },
                }}
                onChange={handleDobChange}
              />
            </LocalizationProvider>
            <FloatingLabel
              controlId="floatingInput"
              label="State"
              className="my-3 floatingInput "
            >
              <Form.Control
                autoComplete="off"
                type="text"
                className="required-input"
                name="state"
                onChange={(event) => onChangeHandler(event, setformData)}
                placeholder="State"
                value={formData.state}
                isInvalid={validated && !formData.state}
                required
              />
            </FloatingLabel>
            <div className="d-flex align-items-center mb-3">
              <div className="mx-2">Family Status</div>
              <div className="mx-2 d-flex">
                <div className="mx-2">
                  <input
                    id="single"
                    type="radio"
                    name="family_status"
                    className={`mt-3`}
                    checked={formData.family_status == "single"}
                    onChange={(event) => {
                      handleOnchangeCheckbox(event, "single", setformData);
                    }}
                  />{" "}
                  <label htmlFor="single">Single</label>
                </div>
                <div className="mx-2 ">
                  <input
                    id="married"
                    type="radio"
                    name="family_status"
                    className={`mt-3`}
                    checked={formData.family_status == "married"}
                    onChange={(event) => {
                      handleOnchangeCheckbox(event, "married", setformData);
                    }}
                  />{" "}
                  <label htmlFor="married">Married</label>
                </div>
                <div className="mx-2">
                  <input
                    id="divorced"
                    type="radio"
                    name="family_status"
                    className={`mt-3`}
                    checked={formData.family_status == "divorced"}
                    onChange={(event) => {
                      handleOnchangeCheckbox(event, "divorced", setformData);
                    }}
                  />{" "}
                  <label htmlFor="divorced">Divorced</label>
                </div>
              </div>
            </div>
            {formData.family_status == "married" && (
              <div className="family-status-inputs">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Spouse First Name"
                  className="mb-3 floatingInput "
                >
                  <Form.Control
                    autoComplete="off"
                    name="spouse_firstname"
                    className="required-input"
                    type="text"
                    onChange={(event) => onChangeHandler(event, setformData)}
                    value={formData.spouse_firstname}
                    placeholder="Spouse First Name"
                    isInvalid={validated && !formData.spouse_firstname}
                    required
                  />
                </FloatingLabel>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Spouse Last Name"
                  className="mb-3 floatingInput "
                >
                  <Form.Control
                    autoComplete="off"
                    name="spouse_lastname"
                    className="required-input"
                    type="text"
                    value={formData.spouse_lastname}
                    onChange={(event) => onChangeHandler(event, setformData)}
                    placeholder="Spouse Last Name"
                    isInvalid={validated && !formData.spouse_lastname}
                    required
                  />
                </FloatingLabel>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="Spouse Date of Birth"
                    maxDate={moment()}
                    value={
                      formData["spouse_dob"] ? formData["spouse_dob"] : null
                    }
                    className={`required-input normal-picker w-100 ${
                      validated && !formData.spouse_dob && "is-invalid"
                    } `}
                    onChange={(date) =>
                      handleDateChange(date, "spouse_dob", setformData)
                    }
                    slotProps={{
                      textField: {
                        required: true,
                      },
                    }}
                  />
                </LocalizationProvider>

                <div className="d-flex align-items-center">
                  <div className="mx-2">Spouse Employment</div>
                  <div className="mx-2">
                    <input
                      id="Working"
                      type="radio"
                      name="spouse_employment"
                      className={`mt-3`}
                      checked={formData.spouse_employment == "Working"}
                      onChange={(event) => {
                        handleRadioButton(event, "Working", setformData);
                      }}
                    />{" "}
                    <label htmlFor="Working">Working</label>
                  </div>
                  <div className="mx-2">
                    <input
                      id="not-working"
                      type="radio"
                      name="spouse_employment"
                      className={`mt-3`}
                      checked={formData.spouse_employment == "not-working"}
                      onChange={(event) => {
                        handleRadioButton(event, "not-working", setformData);
                      }}
                    />{" "}
                    <label htmlFor="not-working">Not working</label>
                  </div>
                </div>
              </div>
            )}
            {formData.family_status == "divorced" && (
              <div className="d-flex align-items-center">
                <div className="mx-2">
                  Does divorce decree include retirement benefits to ex-spouse?
                </div>
                <div className="mx-2 d-flex">
                  <div className="mx-2">
                    <input
                      id="divorceBenefityes"
                      type="radio"
                      name="divorce_decree_include_retirement_benefits_ex_spouse"
                      className={`mt-3`}
                      checked={
                        formData.divorce_decree_include_retirement_benefits_ex_spouse ==
                        "yes"
                      }
                      onChange={(event) => {
                        handleRadioButton(event, "yes", setformData);
                      }}
                    />{" "}
                    <label htmlFor="divorceBenefityes">Yes</label>
                  </div>
                  <div className="mx-2">
                    <input
                      id="divorceBenefitNo"
                      type="radio"
                      name="divorce_decree_include_retirement_benefits_ex_spouse"
                      className={`mt-3`}
                      checked={
                        formData.divorce_decree_include_retirement_benefits_ex_spouse ==
                        "no"
                      }
                      onChange={(event) => {
                        handleRadioButton(event, "no", setformData);
                      }}
                    />{" "}
                    <label htmlFor="divorceBenefitNo">No</label>
                  </div>
                </div>
              </div>
            )}

            <div className="d-flex align-items-center  mb-3">
              <div className="mx-2">
                Do you have children?{" "}
                <OverlayTrigger
                  trigger="click"
                  rootClose
                  key={"top"}
                  placement={"top"}
                  overlay={
                    <Popover id={`popover-positioned-top`}>
                      <Popover.Body>
                        Please include any children you have with current or
                        former partners and any other children you may have
                        adopted but that are not biologically related to you.
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <i
                    className="fa-solid fa-circle-info info-popover"
                    style={{ color: "grey", fontSize: "13px" }}
                  ></i>
                </OverlayTrigger>
              </div>
              <div className="mx-2 d-flex">
                <div className="mx-2">
                  <input
                    id="do_you_have_childrenyes"
                    type="radio"
                    name="do_you_have_children"
                    className={`mt-3`}
                    checked={formData.do_you_have_children == "yes"}
                    onChange={(event) => {
                      handleOnchangeCheckbox(event, "yes", setformData);
                    }}
                  />{" "}
                  <label htmlFor="do_you_have_childrenyes">Yes</label>
                </div>
                <div className="mx-2">
                  <input
                    id="do_you_have_childrenNo"
                    type="radio"
                    name="do_you_have_children"
                    className={`mt-3`}
                    checked={formData.do_you_have_children == "no"}
                    onChange={(event) => {
                      handleOnchangeCheckbox(event, "no", setformData);
                    }}
                  />{" "}
                  <label htmlFor="do_you_have_childrenNo">No</label>
                </div>
              </div>
            </div>
            {formData.do_you_have_children &&
              formData.do_you_have_children == "yes" && (
                <div>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="How Many?"
                    className="mb-3 floatingInput "
                  >
                    <Form.Control
                      autoComplete="off"
                      name="no_of_children"
                      className="required-input"
                      type="number"
                      value={formData.no_of_children}
                      onChange={(event) => onChangeHandler(event, setformData)}
                      placeholder="How Many?"
                      isInvalid={validated && !formData.no_of_children}
                      required
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="How many still living at home?"
                    className="mb-3 floatingInput  "
                  >
                    <Form.Control
                      autoComplete="off"
                      name="dependent_children"
                      className="required-input"
                      type="number"
                      value={formData.dependent_children}
                      onChange={(event) => onChangeHandler(event, setformData)}
                      placeholder="How many still living at home?"
                      isInvalid={validated && !formData.dependent_children}
                      required
                    />
                  </FloatingLabel>
                </div>
              )}
            <div className="d-flex align-items-center mb-3">
              <div className="mx-2">Career</div>
              <div className="mx-2 d-flex">
                <div className="mx-2">
                  <input
                    id="carreryes"
                    type="radio"
                    name="lite_pay_career"
                    className={`mt-3`}
                    checked={formData.lite_pay_career == "yes"}
                    onChange={(event) => {
                      handleOnchangeCheckbox(event, "yes", setformData);
                    }}
                  />{" "}
                  <label htmlFor="carreryes">Yes</label>
                </div>
                <div className="mx-2">
                  <input
                    id="carrerNo"
                    type="radio"
                    name="lite_pay_career"
                    className={`mt-3`}
                    checked={formData.lite_pay_career == "no"}
                    onChange={(event) => {
                      handleOnchangeCheckbox(event, "no", setformData);
                    }}
                  />{" "}
                  <label htmlFor="carrerNo">No</label>
                </div>
              </div>
            </div>
            {/* {formData.lite_pay_career == "yes" && ( */}
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Career Start Date"
                value={
                  formData["lite_pay_start_year"]
                    ? formData["lite_pay_start_year"]
                    : null
                }
                className={`required-input normal-picker w-100 mb-3 ${
                  validated && !formData.lite_pay_start_year
                    ? " is-invalid"
                    : ""
                } `}
                maxDate={moment()}
                slotProps={{
                  textField: {
                    required: true,
                  },
                }}
                onChange={(date) =>
                  handleDateChange(date, "lite_pay_start_year", setformData)
                }
              />
            </LocalizationProvider>

            {/* )} */}
            <div className="d-flex align-items-center  mb-3">
              <div className="mx-2">Do you have military service?</div>

              <div className="mx-2 d-flex">
                <div className="mx-2">
                  <input
                    id="militaryServiceyes"
                    type="radio"
                    name="have_military_service"
                    className={`mt-3`}
                    checked={formData.have_military_service == "yes"}
                    onChange={(event) => {
                      handleOnchangeCheckbox(event, "yes", setformData);
                    }}
                  />{" "}
                  <label htmlFor="militaryServiceyes">Yes</label>
                </div>
                <div className="mx-2">
                  <input
                    id="militaryServiceNo"
                    type="radio"
                    name="have_military_service"
                    className={`mt-3`}
                    checked={formData.have_military_service == "no"}
                    onChange={(event) => {
                      handleOnchangeCheckbox(event, "no", setformData);
                    }}
                  />{" "}
                  <label htmlFor="militaryServiceNo">No</label>
                </div>
              </div>
            </div>
            {formData.have_military_service == "yes" && (
              <div>
                <div className="">
                  <div className="mx-2 my-2">How many years and months?</div>
                  <div className="d-flex">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Years"
                      className="mb-3  floatingInput-milartry-service "
                    >
                      <Form.Control
                        autoComplete="off"
                        type="number"
                        className="required-input"
                        value={formData.military_service_years}
                        name="military_service_years"
                        onChange={(event) =>
                          onChangeHandler(event, setformData)
                        }
                        placeholder="Years"
                        isInvalid={
                          validated && !formData.military_service_years
                        }
                        required
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Months"
                      className="mb-3  floatingInput-milartry-service "
                    >
                      <Form.Control
                        autoComplete="off"
                        type="number"
                        className="required-input"
                        value={formData.military_service_months}
                        name="military_service_months"
                        onChange={(event) =>
                          onChangeHandler(event, setformData)
                        }
                        placeholder="Months"
                        isInvalid={
                          validated && !formData.military_service_months
                        }
                        required
                      />
                    </FloatingLabel>
                  </div>
                </div>

                <div className="d-flex align-items-center">
                  <div className="mx-2">
                    Are you currently receiving a military pension?
                  </div>

                  <div className="mx-2 d-flex">
                    <div className="mx-2">
                      <input
                        id="militaryPensionyes"
                        type="radio"
                        name="s2s3_receiving_pension"
                        className={`mt-3`}
                        checked={formData.s2s3_receiving_pension == "yes"}
                        onChange={(event) => {
                          handleRadioButton(event, "yes", setformData);
                        }}
                      />{" "}
                      <label htmlFor="militaryPensionyes">Yes</label>
                    </div>
                    <div className="mx-2">
                      <input
                        id="militaryPensionNo"
                        type="radio"
                        name="s2s3_receiving_pension"
                        className={`mt-3`}
                        defaultChecked
                        checked={formData.s2s3_receiving_pension == "no"}
                        onChange={(event) => {
                          handleRadioButton(event, "no", setformData);
                        }}
                      />{" "}
                      <label htmlFor="militaryPensionNo">No</label>
                    </div>
                  </div>
                </div>

                <div className="d-flex align-items-center">
                  <div className="mx-2">
                    Are you also receiving military disability pension?
                  </div>

                  <div className="mx-2 d-flex">
                    <div className="mx-2">
                      <input
                        id="militaryDisabilityPensionyes"
                        type="radio"
                        name="s2s3_receiving_disability_pension"
                        className={`mt-3`}
                        checked={
                          formData.s2s3_receiving_disability_pension == "yes"
                        }
                        onChange={(event) => {
                          handleRadioButton(event, "yes", setformData);
                        }}
                      />{" "}
                      <label htmlFor="militaryDisabilityPensionyes">Yes</label>
                    </div>
                    <div className="mx-2">
                      <input
                        id="militaryDisabilityPensionNo"
                        type="radio"
                        name="s2s3_receiving_disability_pension"
                        className={`mt-3`}
                        checked={
                          formData.s2s3_receiving_disability_pension == "no"
                        }
                        onChange={(event) => {
                          handleRadioButton(event, "no", setformData);
                        }}
                      />{" "}
                      <label htmlFor="militaryDisabilityPensionNo">No</label>
                    </div>
                  </div>
                </div>

                <div className="d-flex align-items-center">
                  <div className="mx-2">
                    Deposit completed, or in process of completion?
                  </div>

                  <div className="mx-2 d-flex">
                    <div className="mx-2">
                      <input
                        id="militaryDeposityes"
                        type="radio"
                        name="deposit_made"
                        className={`mt-3`}
                        checked={formData.deposit_made == "yes"}
                        onChange={(event) => {
                          handleRadioButton(event, "yes", setformData);
                        }}
                      />{" "}
                      <label htmlFor="militaryDeposityes">Yes</label>
                    </div>
                    <div className="mx-2">
                      <input
                        id="militaryDepositNo"
                        type="radio"
                        name="deposit_made"
                        className={`mt-3`}
                        checked={formData.deposit_made == "no"}
                        onChange={(event) => {
                          handleRadioButton(event, "no", setformData);
                        }}
                      />{" "}
                      <label htmlFor="militaryDepositNo">No</label>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="d-flex align-items-center mb-3">
              <div className="mx-2">Agency</div>
              <div className="mx-2 d-flex">
                <div className="mx-2">
                  <input
                    id="USPS"
                    type="radio"
                    name="lite_pay_agency"
                    className={`mt-3`}
                    checked={formData.lite_pay_agency == "USPS"}
                    onChange={(event) => {
                      handleOnchangeCheckbox(event, "USPS", setformData);
                    }}
                  />{" "}
                  <label htmlFor="USPS">USPS</label>
                </div>
                <div className="mx-2">
                  <input
                    id="Non-Postal"
                    type="radio"
                    name="lite_pay_agency"
                    className={`mt-3`}
                    checked={formData.lite_pay_agency == "Other"}
                    onChange={(event) => {
                      handleOnchangeCheckbox(event, "Other", setformData);
                    }}
                  />{" "}
                  <label htmlFor="Non-Postal">Non-Postal</label>
                </div>
              </div>
            </div>
            <div className="mx-2">
              <input
                id="provisions"
                type="checkbox"
                className="label-check-fa allow_email_correspondence report-section-input"
                name="special_provisions"
                checked={formData["special_provisions"] === "1" ? true : false}
                onChange={(event) => {
                  handleOnchangeCheckbox(
                    event,
                    event.target.checked ? "1" : "0",
                    setformData
                  );
                }}
              />{" "}
              <label htmlFor="provisions">
                {" "}
                Check for Special Provisions/6(c)
              </label>
            </div>
            {validated && (
              <div className="red-text text-center mt-2">
                *Complete required fields to continue
              </div>
            )}
            <div className="btn-wrapper">
              <div className="text-center mt-4">
                <button className="btn-next btn question-next" type="submit">
                  CONTINUE
                </button>
              </div>
            </div>
          </Form>
        </div>
      </Card.Body>
    </>
  );
};

export default PaystubForm;
