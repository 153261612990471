import React from "react";

const RainyDay = () => {
  return (
    <div className="article-section">
      <div className="container">
        <ol start="62" type="1">
          <li>
            <strong>Rainy-Day Fund</strong> The term "Emergency Fund" refers to
            a savings account equal to at least three to six months of living
            expenses in case of an unexpected financial emergency, such as major
            house repairs or sudden illness. This account should be relatively
            liquid and used for large expenses that are often unforeseen.
            Emergency Savings are much larger and usually the result of a
            successful Rainy-Day Fund.
          </li>
          <li>
            Rainy Day Funds may sound like Emergency Savings, but they’re
            intended to pay for the occasional, more frequent smaller expenses.
            For example, let’s say your refrigerator suddenly stops working, and
            you need to replace it, or your expenses related to children’s
            sports, summer camp, etc.
          </li>
          <li>
            A Rainy-Day Fund can help limit your use of alternative methods,
            such as a Credit Card with a high-interest rate. Remember, when you
            pay interest, you are losing money. But when you earn interest, your
            money is making more money all by itself.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default RainyDay;
