import React from "react";

const Pay2 = () => {
  return (
    <div className="article-section" data-screen="pay-s2">
      <div className="container">
        <ol start="7" type="1">
          <li>
            My starting point is always to make sure that you understand where
            your money is going and what the codes on your check mean. In my
            experience, this is probably the first time in your career that
            someone has done this for you. Most people are typically concerned
            with one thing; what they take home but understanding your check,
            the deductions, and benefits, are critical to maximizing your
            retirement planning. Let’s get started.
            <ul style={{ listStyle: "disc" }}>
              <li>Base Rate of Pay</li>
              <li>Payroll Deductions</li>
              <li>
                FEDERAL and STATE TAX: The most important part to understand
                here is that we need to make sure you’re paying what you owe to
                the Government.
              </li>
            </ul>
          </li>

          <li>
            <strong>We don’t want you to pay more than you owe.</strong> The
            easiest way to determine this is at the end of the year when you
            file your taxes, are you typically getting money back or do you have
            to pay?
          </li>

          <li className="agent-tip">
            <strong>Agent Tip:</strong> If they’re paying a lot of money into
            their taxes and getting several thousand dollars back at the end of
            the year; they’re contributing significantly more than is necessary.
            Example: If an employee gets $3,000 back as an IRS refund. Divide
            $3,000 by 26 Pay-Periods. You can show them they’re overfunding
            their taxes by roughly $115 per Paycheck.
          </li>

          <li>
            The fact that you’re getting back several thousand dollars at the
            end of the year tells me you’re paying way more to the Government
            than you should be. You would be better off increasing the amount
            you’re claiming, take that extra money or any portion of it (even if
            it’s only $50.00 a Pay Period) and slide that over to a Credit Union
            or some type of savings account. I can help you do this.
            <ul style={{ listStyle: "disc" }}>
              <li>Sick and Annual Leave</li>
              <li>USPS Retirement (If applicable)</li>
              <li>Net to Bank</li>
            </ul>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Pay2;
