import React from "react";

const Fegli = () => {
  return (
    <div className="article-section" data-screen="ss">
      <div className="container">
        <ol start="59" type="1">
          <li>
            <strong>Social Security</strong> will be an integral part of your
            Retirement. Social Security can be started as early as 62, deferred
            to Full-Retirement Age (typically 67) or delayed to 70. To qualify
            for Social Security benefits, you must work and pay into Social
            Security for 10 years (40 credits).
          </li>
          <li>
            In addition, Social Security Benefits are taxed in retirement. Up to
            85% of your Social Security Benefit will be taxed in retirement,
            based upon your retirement income.
          </li>
          <li>Do you know what your estimated Social Security Benefit is?</li>
        </ol>
      </div>
    </div>
  );
};

export default Fegli;
