import React from "react";

const Fegli = () => {
  return (
    <div className="article-section" data-screen="tsp">
      <div className="container">
        <ol start="47" type="1">
          <li>
            Your Thrift Savings Plan is very similar to a private corporation’s
            401k or a Traditional IRA. Both have one thing in common: they’re
            what is called Tax Deferred.
          </li>
          <li>
            <strong>Tax Deferred</strong> means that you contribute/invest the
            money before the government taxes it. In your case, it’s the TSP
            $116 you contribute into TSP. The Government then gives you a
            certain amount of “free money” called matching. That money then
            grows at whatever rate of return you’re getting in your chosen
            funds. Then, when you retire and start pulling your money out, the
            government taxes you on whatever you’re taking out. It is typically
            between 20-30%, essentially sharing your retirement income with the
            government. Does this make sense?
          </li>
          <li className="agent-tip">
            <strong>Agent Tip:</strong> You must help them understand that
            traditional IRAs still need to be taxed. Versus a ROTH that has
            already been taxed and the interest received is 100% theirs.
          </li>
          <li>
            <strong>
              This makes a traditional 401k a less-than-perfect place
            </strong>{" "}
            to put your money. Keep in mind that the government incentivizes
            employers like the government to encourage employees to participate
            in programs like this; knowing they will get their piece of your
            retirement and investment. In your case, the incentive or Employer
            Matching. You can see here in this section your USPS Employer
            Matching is 5%. Making this a great place for your first 5%. This is
            the most important part for you to understand about your Thrift
            Savings Plan.
          </li>
          <li>
            <strong>The First 5%</strong> you’re currently contributing 6% of
            your salary or $116 (reference the paystub line TSP) Let’s do the
            math: Take your bi-weekly contribution of $116 and divide it by 6,
            which tells us each percent you contribute is worth $19.33. Then we
            multiply this by 5 (for the 5% matching you’re getting) to tell us
            the Maximum Amount you should be putting into your TSP to capitalize
            on the Government’s free money. So that’s $96.66 that’s
            automatically doubled with their matching, for a total of $193.33
            every pay period.
          </li>
          <li>
            <strong>That’s a great place to put your First 5%</strong>. The
            problem is you have this other 1% that’s going in that equals $19.33
            with NO “matching” on it. And don’t forget, you must Pay Taxes on
            this $19.33 when you take it out later. That’s why it’s so important
            to slide this money over into a ROTH IRA.
          </li>
          <li>
            <strong>TSP Funds</strong> Do you have any idea what Fund(s) your
            money is invested in right now? Do you remember doing anything or
            did you just leave it in the default fund? Typically, most people
            leave their money in the Default fund which is the “G-Fund”, this is
            the Fund with the least Risk to your money. The problem is, for the
            most part, it’s not even keeping up with Inflation and at best
            you’re going to average about 1.5%. Did you realize that?
          </li>
          <li>
            <strong>L Fund</strong> I’d recommend you look at the L-Fund. It
            encompasses all of the other Funds, which have different risk
            factors associated with them, and takes into consideration the
            amount of time you have left until retirement. The L-Fund basically
            calculates risk based on the year you want to retire and shifts your
            money into the least risky funds the closer you get to your
            retirement date.
          </li>
          <li>
            <strong>TSP Limited Access</strong> You cannot access your money
            until Age 59 ½ without penalty. Remember on your paycheck stub, you
            had a loan on your TSP? So obviously at some point you needed to
            access that money, which is quite normal, and it’s very likely
            sometime between now and age 59 ½ you could need to access it again,
            right?
          </li>
          <li>
            And that’s where{" "}
            <strong>we have a bit of a gap or “pitfall”</strong> with the way
            the Thrift Savings Plan is set up. Basically, you’re putting your
            money into this program every pay period and you really can’t even
            touch it until you’re 59 ½. That can put people in a bind when
            they’re forced to pull out their money and end up having to pay it
            all back. Who needs another “loan” payment, right? So that’s why
            this isn’t really the most flexible place for people to stick their
            money. But again, it allows you to capitalize on the government’s
            free 5% matching.
          </li>
          <li>
            <strong>Extra Contributions</strong> Anything over and above the 5%
            contribution, we will slide to your ROTH IRA. This gives you the
            opportunity for tax-free growth and the ability to receive ALL your
            interest earned. Not just the 75% the government leaves you with –
            after paying taxes.
          </li>
          <li>
            <strong>20-30% Taxes Upon Distribution</strong> Remember you will
            also pay taxes on this money once it is distributed to you. You can
            expect somewhere between 20-30% Federal and State to be deducted
            from your TSP income.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Fegli;
